import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { WatermarkPosition, watermarkPositions } from './WatermarkPosition'
import { watermarkForm } from 'saas/store/watermark/WatermarkFormStore'
import { InputNumber } from 'component/Input/InputNumber'
import { valueType } from 'antd/es/statistic/utils'
import css from './WatermarkSelector.module.scss'
import { Space } from 'component/Space'
import { i18n } from 'i18n'

export const WatermarkSelectorForm = observer(() => {
  const { widthPercent, indentPercent, position, transparency } =
    watermarkForm.it

  // center-center
  const isDisabled =
    position.value === watermarkPositions[watermarkPositions.length - 1]

  return (
    <Vertical gap={16} className={css.form}>
      <Vertical gap={8}>
        <Typo size={13} semi>
          {i18n('company.Watermarks.form.Position')}
        </Typo>
        <WatermarkPosition
          value={
            position.value as unknown as (typeof watermarkPositions)[number]
          }
          onChange={(p) => {
            if (p === watermarkPositions[watermarkPositions.length - 1]) {
              indentPercent.value = 0
            }
            position.onChange(
              p as unknown as (typeof watermarkPositions)[number]
            )
          }}
        />
      </Vertical>
      <Space wide gap={16}>
        <Vertical gap={8} wide>
          <Typo size={13} semi>
            {i18n('company.Watermarks.form.RightOffset')}
          </Typo>
          <InputNumber
            className={css.inputNumber}
            value={widthPercent.value}
            onChange={(value: valueType | null) => {
              widthPercent.onChange(value as number)
            }}
            defaultValue={0}
            min={0}
            max={100}
            suffix="%"
          />
        </Vertical>
        <Vertical gap={8} wide>
          <Typo size={13} semi>
            {i18n('company.Watermarks.form.BottomOffset')}
          </Typo>
          <InputNumber
            className={css.inputNumber}
            type="number"
            value={indentPercent.value}
            onChange={(value: valueType | null) => {
              indentPercent.onChange(value as number)
            }}
            defaultValue={0}
            min={0}
            max={100}
            suffix="%"
            disabled={isDisabled}
          />
        </Vertical>
      </Space>
      <Vertical gap={8} wide>
        <Typo size={13} semi>
          {i18n('company.Watermarks.form.Transparency')}
        </Typo>
        <InputNumber
          className={css.inputNumber}
          type="number"
          value={transparency.value}
          onChange={(value: valueType | null) => {
            transparency.onChange(value as number)
          }}
          defaultValue={0}
          min={0}
          max={10}
          suffix=" "
        />
      </Vertical>
    </Vertical>
  )
})
