import { makeAutoObservable, observable } from 'mobx'
import { api } from 'store/api'
import { BotFeed } from 'type/Feed'
import { parseDate } from 'util/date'

export class BotFeedStore {
  private _json: Readonly<BotFeed>

  constructor(json: BotFeed) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
  }

  get json(): Readonly<BotFeed> {
    return this._json
  }

  set json(value: BotFeed) {
    this._json = value
  }

  get created_at(): Date | undefined {
    return parseDate(this.json.created_at)
  }

  async start() {
    const { campaign_id, company_id, product_id } = this.json
    this.json = await api.startBotFeed(company_id, product_id, campaign_id)
  }
}
