import { Upload } from 'component/Upload'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Image } from 'component/Image'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { Icon } from 'icon/Icon'
import css from './WatermarkUpload.module.scss'
import { Gap } from 'component/Space/Gap'
import { i18n } from 'i18n'

type Props = {
  value: File | string | undefined
  onChange: (file: File) => void
}

export const WatermarkUpload = ({ value, onChange }: Props) => {
  const onFile = useLatestCallback((file: File) => {
    onChange(file)
  })

  const preview =
    value && typeof value !== 'string' ? URL.createObjectURL(value) : value

  return (
    <Vertical wide className={css.wrapper}>
      <Typo size={13} semi>
        {i18n('company.Watermarks.EditModal.Label.FileUpload.Watermark')}
      </Typo>
      <Gap height={8} />
      <div className={css.upload}>
        <Upload accept="image" width={312} height={148} onFile={onFile}>
          {!preview && (
            <Vertical wide gap={8} center>
              <div className={css.iconUpload}>
                <Icon size={24} name="upload_file" />
              </div>
              <Vertical wide>
                <Typo secondary>
                  {i18n('company.Watermarks.EditModal.Label.FileUpload.Upload')}
                </Typo>
                <Typo secondary>
                  {i18n(
                    'company.Watermarks.EditModal.Label.FileUpload.FileFormat'
                  )}
                </Typo>
              </Vertical>
            </Vertical>
          )}
          {preview && <Image src={preview} />}
        </Upload>
      </div>
    </Vertical>
  )
}
