import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Items } from 'saas/page/group/preview/Items'
import { Search } from 'saas/page/group/preview/Search'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'

export const GroupPreview = observer(() => {
  const onRecalc = useLatestCallback(async () => {
    await groupFormSaas.it.recalc()
    groupPreviewSaas.it.refresh()
  })

  return <Card gap={24} pad={24} minWidth={400} vertical>
    <Space gap={16} opposite>
      <Space gap={12}>
        <Typo size={16} semi>{i18n('group.Items')}</Typo>
        <Button link onClick={onRecalc}>
          <Icon size={16} name="sync" />
          <Typo>{i18n('group.Recalculate')}</Typo>
        </Button>
      </Space>
      <Search />
    </Space>
    <Items />
  </Card>
})
