import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { FeedTable } from 'saas/page/product/bot/feed/FeedTable'
import { bot } from 'saas/store/product/ProductSaasController'
import { renderLoadingState } from 'util/render'

export const Feeds = observer(() => {
  const { search, feeds } = bot.it.feeds
  const stub = renderLoadingState(bot.it.feeds.state)
  const empty = !feeds.length

  if (stub) {
    return <Space height={550} center>
      {stub}
    </Space>
  }

  if (empty) {
    return <Space height={550} center>
      <Typo size={14} secondary>
        {search ? i18n('search.NothingFound') : i18n('feed.NoMailings')}
      </Typo>
    </Space>
  }

  return <FeedTable />
})
