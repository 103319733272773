import { makeAutoObservable } from 'mobx'

export class NumberStore {
  private _value: number | undefined

  constructor(value: number | undefined = undefined) {
    this._value = value
    makeAutoObservable(this)
  }

  get value(): number | undefined {
    return this._value
  }

  set value(value: number | undefined) {
    this._value = value ?? undefined
  }

  get empty(): boolean {
    return this._value === undefined
  }

  readonly onChange = (value: number | null | undefined) => {
    this._value = value ?? undefined
  }
}
