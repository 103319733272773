import { capitalize as capitalizeLodash } from 'lodash'

type EmptyString = '' | null | undefined

export function isEmptyString(value: string | null | undefined): value is EmptyString
export function isEmptyString<T>(value: T | string | null | undefined): value is T | EmptyString
export function isEmptyString(value: string | null | undefined): value is EmptyString {
  return value === null || value === ''
}

export function ensureString(value: string | unknown | null | undefined): string | undefined {
  if (typeof value === 'string') return value
  return undefined
}

export function notEmptyString(value: string | null | undefined): value is string {
  return !!value
}

export function compareStrings(a: string, b: string): number {
  return a.localeCompare(b)
}

export function compareIgnoreCase(a: string, b: string): number {
  const lower = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
  if (lower === 0) return compareStrings(a, b)
  return lower
}

export function compareIgnoreCaseBy<T>(field: (item: T) => string) {
  return (a: T, b: T): number => compareIgnoreCase(field(a), field(b))
}

export function toLowerCase(value: string): string {
  return value.toLowerCase()
}

export function capitalize(value: string | null | undefined): string {
  return capitalizeLodash(value ?? '')
}

export function extractList(text: string | null | undefined): string[] {
  if (!text) return []
  return text.split(',').map(r => r.trim()).filter(r => !!r)
}

export function comparingKnownStrings(known: readonly string[], bad: readonly  string[], ignoreCase?: boolean) {
  const compare = ignoreCase ? compareIgnoreCase : compareStrings
  return function compareKnownStrings(a: string, b: string): number {
    const ax = bad.findIndex(x => compare(x, a) === 0)
    const bx = bad.findIndex(x => compare(x, b) === 0)
    if (ax >= 0 && bx >= 0) return ax - bx
    if (ax >= 0) return -1
    if (ax >= 0) return 1

    const ai = known.findIndex(x => compare(x, a) === 0)
    const bi = known.findIndex(x => compare(x, b) === 0)
    if (ai >= 0 && bi >= 0) return ai - bi
    if (ai >= 0) return -1
    if (ai >= 0) return 1

    return a.localeCompare(b)
  }
}
