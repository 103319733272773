import { clsx } from 'clsx'
import { ReactNode } from 'react'
import css from './Table.module.scss'

interface Props {
  children?: ReactNode | ReactNode[]
  className?: string
}

const Table = (props: Props) => {
  const { children } = props
  const className = clsx(props.className, css.table)

  return (
    <table className={className}>
      {children}
    </table>
  )
}

export const Tr = ({ children, className }: Props) => (
  <tr className={className}>
    {children}
  </tr>
)

interface TdProps {
  children?: ReactNode | ReactNode[]
  className?: string
  colSpan?: number
  width?: number
}

export const Td = (props: TdProps) => (
  <td {...props} />
)

const TBody = ({ children, className }: Props) => (
  <tbody className={className}>
  {children}
  </tbody>
)

const THead = ({ children, className }: Props) => (
  <thead className={className}>
  <tr>
    {children}
  </tr>
  </thead>
)

Table.Tr = Tr
Table.Td = Td
Table.TBody = TBody
Table.THead = THead

export { Table }
