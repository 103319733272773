import { makeAutoObservable } from 'mobx'
import { BotFeedStore } from 'saas/store/product/bot/BotFeedStore'
import { BotFeed, getCampaignStatusLabel } from 'type/Feed'
import { LoadingState } from 'type/Store'
import { formatDateTime, parseDate } from 'util/date'
import { matchSearch } from 'util/search'
import { by } from 'util/sort'

export class BotFeedsStore {
  private _state: LoadingState = 'none'
  private _filtered: BotFeedStore[] = []
  private _search = ''
  private readonly limit = 10
  private _page: number = 1

  constructor() {
    makeAutoObservable(this)
  }

  init(feeds: BotFeed[]) {
    this._filtered = feeds.map(feed => new BotFeedStore(feed))
      .sort(by(it => it.created_at?.getTime(), true))
    this._state = 'ready'
  }

  set page(value: number) {
    this._page = Math.max(1, Math.min(value, this.pages))
  }

  get state(): LoadingState {
    return this._state
  }

  get page(): number {
    return this._page
  }

  get feeds(): BotFeedStore[] {
    const to = this.page * this.limit
    const from = to - this.limit
    return this.filtered.slice(from, to)
  }

  private get filtered(): BotFeedStore[] {
    return this._filtered.filter(feed => matchSearch(this.search, [
      feed.json.campaign_name,
      feed.json.campaign_text,
      getCampaignStatusLabel(feed.json.campaign_status),
      formatDateTime(parseDate(feed.json.sent_at)),
    ]))
  }

  get pages(): number {
    return Math.ceil(this.filtered.length / this.limit)
  }

  add(feed: BotFeed) {
    this._filtered.unshift(new BotFeedStore(feed))
  }

  get search(): string {
    return this._search
  }

  set search(value: string) {
    this._search = value
  }
}
