import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { CustomSelfie } from 'saas/page/product/tryon/SelfiesPanel/CustomSelfie'
import { tryon } from 'saas/store/product/ProductSaasController'

type Props = {
  female?: boolean
}

export const CustomSelfies = observer(({ female }: Props) => {
  const store = tryon.it.edit.selfies
  const selfies = female ? store.female : store.male
  const title = female ? i18n('tryon.FemaleSelfies') : i18n('tryon.MaleSelfies')
  const error = female ? store.errorFemale : store.errorMale

  return <Card vertical gap={8} pad={12} secondary error={!!error}>
    <Vertical>
      <Typo size={13} medium>{title}</Typo>
      {error && <Typo size={11} error>{error}</Typo>}
    </Vertical>
    <Space gap={12} wrap>
      <CustomSelfie selfie={selfies[0]} />
      <CustomSelfie selfie={selfies[1]} />
      <CustomSelfie selfie={selfies[2]} />
      <CustomSelfie selfie={selfies[3]} />
      <CustomSelfie selfie={selfies[4]} />
    </Space>
  </Card>
})
