import { reaction, runInAction } from 'mobx'
import { chart } from 'saas/store/stat/ChartStore'
import { statistics } from 'saas/store/stat/StatisticsStore'
import { tops } from 'saas/store/stat/TopItemsStore'
import { totals } from 'saas/store/stat/TotalsStore'
import { home } from 'store/home'
import { me } from 'store/me'
import { StatRequest } from 'type/Statistics'
import { skipAsync } from 'util/async'

export class StatisticsController {
  company_id?: string

  constructor() {
    // delay needed to merge changes of start and end
    reaction(this.buildRequest, this.update, { delay: 10 })
  }

  init = skipAsync(async () => {
    const company_id = home.company.optional?.company_id
    if (this.company_id === company_id) return

    runInAction(() => {
      this.company_id = company_id
      totals.reset()
      chart.reset()
      tops.reset()
      statistics.reset(company_id)
    })

    await statistics.load(company_id)
  })

  private readonly buildRequest = (): StatRequest | undefined => {
    if (!me.present) return undefined

    const { user_id } = me.user
    const { company_id, interval, key } = statistics
    if (!company_id) return undefined

    const from = interval.start.format('YYYY-MM-DD')
    const to = interval.end.add(1, 'day').format('YYYY-MM-DD')
    const api_key = statistics.keys.find(it => it.key_name === key)?.access_key
    return { user_id, company_id, from, to, api_key }
  }

  private readonly update = skipAsync(async (request: StatRequest | undefined) => {
    if (!request) return

    const _chart = chart.update(request)
    const _totals = totals.update(request)
    const _tops = tops.update(request)
    await _chart
    await _totals
    await _tops
  })
}

export const statisticsController = new StatisticsController()
