import { FormStore } from "form/store/FormStore"
import { CompanySaasStore } from "../CompanySaasStore"
import { StringStore } from "store/base/StringStore"
import { WatermarkItemStore } from "./WatermarkItemStore"
import { makeAutoObservable } from "mobx"
import { ObjectStore } from "store/base/ObjectStore"
import { api } from "store/api"
import { mx } from "store/base/mx"
import { WatermarkRequest } from "type/Watermark"
import { watermarkPositions } from "saas/page/company/manage/sections/Watermark/components/WatermarkSelector/WatermarkPosition"

export class WatermarkModalStore {
  private _busy = false
  readonly company: CompanySaasStore
  readonly watermark: WatermarkItemStore | undefined

  form = new FormStore()
  readonly name = this.form.field(new StringStore(''), { required: true })
  readonly image = this.form.field(new ObjectStore<File | string>(), { required: true })
  readonly accessKey = this.form.field(new StringStore())

  // состояние модального окна: создание/редактирование
  readonly isEditing: boolean

  constructor(company: CompanySaasStore, watermark?: WatermarkItemStore, isEditing?: boolean) {
    makeAutoObservable(this)
    this.company = company
    this.watermark = watermark
    this.name.value = watermark?.watermark_name || ''
    this.image.value = watermark?.watermark_image[0]?.url
    if (isEditing && watermark?.access_key) {
      this.accessKey.value = watermark?.access_key as string
    }
    this.isEditing = isEditing || false
  }

  get busy(): boolean {
    return this._busy
  }

  private set busy(value: boolean) {
    this._busy = value
  }

  applyWatermark = async () => {
    this.busy = true
    let image = this.image.value

    let width = 0
    let height = 0

    if (typeof this.image.value !== 'string') {
      const img = await createImageBitmap(this.image.value as File)
      width = img.width
      height = img.height
      image = await api.uploadImage(this.image.value as File)
    }

    const request: WatermarkRequest = {
      company_id: this.company.company_id,
      watermark_name: String(this.name.value),
      url: image as string,
      access_key: this.accessKey.value,
      watermark_data: {
        overlay: false,
        transparency: 5,
        widthPercent: 0,
        indentPercent: 0,
        blur: 0,
        position: watermarkPositions[0]
      }
    }

    if (width > 0) {
      request.watermark_size = `${width}x${height}`
    }

    if (this.isEditing) {
      request.watermark_data = this.watermark?.watermark_data
    }

    if (this.isEditing) {
      const [value] = await api.updateWatermark({
        ...request,
        watermark_id: this.watermark?.watermark_id as string
      })
      this.watermark!.applyJson(value!)
    } else {
      const [value] = await api.createWatermark(request)
      this.company.watermarks.add(value as WatermarkItemStore)
    }

    this.busy = true
    watermarkModal.close()
  }

}

export const watermarkModal = mx.ref<WatermarkModalStore>()