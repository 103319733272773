import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { PageInput } from 'feature/pagination/PageInput'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'
import { api } from 'store/api'
import { buildUserCsv } from 'type/Bot'

export const Bottom = observer(() => {
  const { bot_id, bot_username } = bot.it.json
  const { page, pages } = bot.it.feeds

  const onPage = useLatestCallback((value: number) => {
    bot.it.feeds.page = value
  })

  const onDownload = useLatestCallback(async () => {
    if (!bot_id) return

    const bot = bot_username ?? 'bot'
    const users = await api.getBotUsers(bot_id)
    const content = buildUserCsv(users)
    const blob = new Blob([content], { type: 'text/csv' })

    const a = document.createElement('a')
    a.setAttribute('href', URL.createObjectURL(blob))
    a.setAttribute('download', `${bot}-users.csv`)
    a.click()
  })

  return <Space gap={16} opposite>
    <Space>
      <PageInput page={page} pages={pages} onChange={onPage} />
    </Space>
    <Space>
      <Button small compact link disabled={!bot_id} action={onDownload}
        icon={<Icon size={16} name="download" />}>
        {i18n('bot.DownloadUsersInCsv')}
      </Button>
    </Space>
  </Space>
})
