import { SearchInput } from 'component/Input/SearchInput'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'

export const Search = observer(() => {
  const { search } = bot.it.feeds

  const onChange = useLatestCallback((value: string) => {
    bot.it.feeds.search = value
  })

  return <SearchInput value={search} placeholder={i18n('label.Search')} onChange={onChange} />
})
