import { Switch } from 'component/Switch/Switch'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const TemplateSwitch = observer(() => {
  const { allowSelfieTemplate } = tryon.it.edit.selfies

  const onChange = useLatestCallback((value: boolean) => {
    tryon.it.edit.selfies.allowSelfieTemplate = value
  })

  return <Switch checked={allowSelfieTemplate} onChange={onChange} />
})
