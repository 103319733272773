import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { watermarkDeleteModal } from 'saas/store/watermark/WatermarkDeleteModalStore'

export const WatermarkDeleteModal = () => {
  const handleClose = () => {
    watermarkDeleteModal.close()
  }

  const handleSubmit = () => {
    companyEdit.it.deleteWatermark(watermarkDeleteModal.it.watermark)
    watermarkDeleteModal.close()
  }

  return (
    <Modal
      title={i18n('company.Watermarks.DeleteModal.Title')}
      onCancel={handleClose}
    >
      <Vertical gap={12}>
        <Typo size={13}>{i18n('company.Watermarks.DeleteModal.Text')}</Typo>
      </Vertical>
      <Space spread gap={12}>
        <Button action={handleClose}>{i18n('common.Cancel')}</Button>
        <Button primary action={handleSubmit}>
          {i18n('common.Delete')}
        </Button>
      </Space>
    </Modal>
  )
}
