import { i18n } from 'i18n'
import { IconName } from 'icon/types'
import { capitalize } from 'util/string'

export type CampaignStatus = 'created' | 'sending' | 'completed' | 'error'

export type BotFeed = {
  company_id: string
  product_id: string
  campaign_id: string

  created_at: string
  updated_at: string
  sent_at?: string

  campaign_name: string
  campaign_text: string
  campaign_status: CampaignStatus
  messages_sent?: string
}

export type CreateBotFeedRequest = {
  user_id: string
  company_id: string
  product_id: string
  campaign_name: string
  campaign_text: string
  campaign_status: string
}

export function getCampaignStatusLabel(status: CampaignStatus | null | undefined): string {
  if (!status) return i18n('review.None')
  switch (status) {
    case 'created':
      return i18n('feed.status.Created')
    case 'sending':
      return i18n('feed.status.Sending')
    case 'completed':
      return i18n('feed.status.Completed')
    case 'error':
      return i18n('feed.status.Error')
    default:
      return capitalize(status).replace(/[_-]/g, ' ')
  }
}

export function getCampaignStatusIcon(status: CampaignStatus | null | undefined): IconName | undefined {
  if (!status) return undefined
  switch (status) {
    case 'created':
      return 'draft'
    case 'sending':
      return 'schedule'
    case 'completed':
      return 'check_circle'
    case 'error':
      return 'error'
    default:
      return undefined
  }
}
