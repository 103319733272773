import { makeAutoObservable } from 'mobx'
import { mx } from 'store/base/mx'
import { WatermarkItemStore } from './WatermarkItemStore'

export class WatermarkDeleteModalStore {
  readonly watermark: WatermarkItemStore

  constructor(watermark: WatermarkItemStore) {
    makeAutoObservable(this)
    this.watermark = watermark
  }
}

export const watermarkDeleteModal = mx.ref<WatermarkDeleteModalStore>()
