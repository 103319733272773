import { formatDateTime, parseDate } from 'util/date'
import { notNil } from 'util/null'

export type BotUserInfo = {
  id?: number
  is_bot?: boolean
  first_name?: string
  last_name?: string
  username?: string
  language_code?: string
}

export type BotUser = {
  tg_user_id: string
  created_at: string
  updated_at: string
  user_info?: BotUserInfo
}

export function buildUserCsv(users: BotUser[]) {
  let content = ''
  content += 'ID,Created,Updated,Username,Name,Lang\n'

  for (const user of users) {
    const id = user.tg_user_id
    const { created_at, updated_at } = user
    const info = user.user_info ?? {}
    const { first_name, last_name } = info
    const created = formatDateTime(parseDate(created_at))
    const updated = formatDateTime(parseDate(updated_at))
    const username = info.username ?? ''
    const name = [first_name, last_name].filter(notNil).join(' ').replaceAll(',', ' ')
    const lang = info.language_code ?? ''
    content += `${id},${created},${updated},${username},${name},${lang}\n`
  }

  return content
}
