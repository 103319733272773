import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Table } from 'component/Table'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { PageInput } from 'feature/pagination/PageInput'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Item } from 'saas/page/product/bot/items/Item'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'

export const Items = observer(() => {
  const { busy, search, visibleItems, page, pages } = groupPreviewSaas.it
  const empty = !visibleItems.length

  const onPage = useLatestCallback((value: number) => {
    groupPreviewSaas.it.page = value - 1
  })

  if (busy) {
    return <Space height={900} center>
      <Spinner />
    </Space>
  }

  if (empty) {
    return <Space height={900} center>
      <Typo size={14} secondary>
        {search ? i18n('search.NothingFound') : i18n('item.NoItems')}
      </Typo>
    </Space>
  }

  return <Vertical gap={16}>
    <Table>
      <Table.THead>
        <Table.Td>{i18n('item.Item')}</Table.Td>
        <Table.Td>{i18n('label.Gender')}</Table.Td>
        <Table.Td>{i18n('collection.Brand')}</Table.Td>
        <Table.Td>{i18n('bot.TelegramLink')}</Table.Td>
      </Table.THead>
      <Table.TBody>
        {visibleItems.map((item) => (
          <Item key={item.item_id} item={item} />
        ))}
      </Table.TBody>
    </Table>
    <PageInput page={page + 1} pages={pages} onChange={onPage} />
  </Vertical>
})
