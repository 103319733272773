import { makeAutoObservable, observable, runInAction } from 'mobx'
import { api } from 'store/api'
import { mx } from 'store/base/mx'
import { me } from 'store/me'
import { GroupItem, GroupItemsRequest } from 'type/ItemGroup'
import { skipAsync } from 'util/async'

export class GroupPreviewStore {
  private readonly group_id: string
  private _count: number = 0
  private _items: GroupItem[] = []
  private _busy = false
  private _page: number = 0
  private _limit: number = 10
  private _search: string = ''

  constructor(group_id: string) {
    makeAutoObservable<this, '_items'>(this, { _items: observable.ref })
    this.group_id = group_id
    void this.refresh()
  }

  get busy(): boolean {
    return this._busy
  }

  get count(): number {
    return this._count
  }

  get page(): number {
    return this._page
  }

  set page(value: number) {
    const page = Math.max(0, Math.min(value, this.pages - 1))
    if (this._page === page) return
    this._page = page
    void this.load()
  }

  get pages(): number {
    return Math.ceil(this._count / this.limit)
  }

  get limit(): number {
    return this._limit
  }

  set limit(value: number) {
    if (this._limit === value) return
    this._limit = value
    this.refresh()
  }

  get search(): string {
    return this._search
  }

  set search(value: string) {
    this._search = value
    this._page = 0
    this.refresh()
  }

  get visibleItems(): GroupItem[] {
    return this._items
  }

  refresh() {
    this._page = 0
    void this.load()
  }

  private readonly load = skipAsync(async () => {
    if (this.busy) return
    try {
      runInAction(() => {
        this._busy = true
      })
      const request = this.buildRequest()
      const response = await api.getGroupItems(request)
      runInAction(() => {
        this._count = response.full_count
        this._items = response.items
        this._busy = false
      })
    } catch {
      runInAction(() => {
        return this._busy = false
      })
    }
  })

  private buildRequest(): GroupItemsRequest {
    return {
      user_id: me.user.user_id,
      group_id: this.group_id,
      query: this.search,
      page: this.page + 1,
      limit: this.limit,
    }
  }
}

export const groupPreviewSaas = mx.ref<GroupPreviewStore>()
