import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'

export const NewForm = observer(() => {
  const { name, message, canSave } = bot.it.form!

  const onCancel = useLatestCallback(async () => {
    bot.it.closeForm()
  })

  const onCreate = useLatestCallback(async () => {
    await bot.it.createFeed()
  })

  return <Vertical wide>
    <TextField field={name} label={i18n('label.Name')} />
    <Gap height={12} />
    <TextField rows={4} field={message} label="Message" />
    <Gap height={24} />
    <Space gap={12} columns>
      <Button filled action={onCancel}>
        {i18n('common.Cancel')}
      </Button>
      <Button primary disabled={!canSave} action={onCreate}>
        {i18n('common.Create')}
      </Button>
    </Space>
  </Vertical>
})
