import { tryon_host } from 'app/config/constants'
import { makeAutoObservable, observable } from 'mobx'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { KeyEditStore } from 'saas/store/product/tryon/KeyEditStore'
import { TryOnEditStore } from 'saas/store/product/tryon/TryOnEditStore'
import { options } from 'saas/store/product/tryon/util'
import { productsConfig } from 'saas/store/products/ProductsConfigStore'
import { TryOnProduct } from 'type/product/TryOnProduct'
import { updateObject } from 'util/object'

function assembleCode(product_id: string) {
  // language=html
  return `
<script async defer src="https://${tryon_host}/widget.js?product=${product_id}"></script>

<script>
  function setupShowoff() {
    const queue = []
    function open(args) { queue.push(['open', args]) }
    window.showoff = { queue, open }
  }
  setupShowoff()
</script>
`.trim() + '\n\n'
}

export class TryOnProductStore {
  readonly company_id: string
  private _json: TryOnProduct
  private _edit!: TryOnEditStore
  private _keyEdit?: KeyEditStore

  constructor(company_id: string, json: TryOnProduct) {
    this.company_id = company_id
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this._edit = new TryOnEditStore(this._json)
  }

  get busy(): boolean {
    const config = productsConfig.get(this.company_id)
    return config.busy
  }

  get code(): string {
    return assembleCode(this.product_id)
  }

  get optionsPreview(): object {
    const { updates } = this.edit
    const json = updateObject(this._json, updates)
    return options(json)
  }

  get product_id(): string {
    return this._json.product_id
  }

  findKey(value: string | undefined): ApiKeyStore | undefined {
    return value ? this.company.keys.find(k => k.value === value) : undefined
  }

  get key(): ApiKeyStore | undefined {
    return this.findKey(this.json.access)
  }

  get json(): TryOnProduct {
    return this._json
  }

  get company(): CompanySaasStore {
    const company = companiesSaas.companies.find(c => c.company_id === this.company_id)
    if (!company) throw new Error('no company')
    return company
  }

  get edit(): TryOnEditStore {
    return this._edit
  }

  get keyEdit(): KeyEditStore | undefined {
    return this._keyEdit
  }

  openKeyEdit() {
    const key = this.key
    this._keyEdit = new KeyEditStore({ key })
  }

  closeKeyEdit() {
    this._keyEdit = undefined
  }

  async saveSettings() {
    if (!this.edit.check()) return
    const { updates } = this.edit
    this.json = updateObject(this._json, updates)
    await this.save()
  }

  async saveKey() {
    const keyEdit = this.keyEdit
    if (!keyEdit || !keyEdit.check()) return
    const update = keyEdit.buildUpdate()
    this.json = updateObject(this._json, update)
    this.closeKeyEdit()
    await this.save()
  }

  async publish(publish: boolean) {
    if (!!this.json.published === publish) return
    const published = publish ? true : undefined
    const update = { published }
    this.json = updateObject(this._json, update)
    await this.save()
  }

  open() {
    this._edit = new TryOnEditStore(this._json)
    this._keyEdit = undefined
  }

  private set json(json: TryOnProduct) {
    json.updated_at = new Date().toISOString()
    this._json = json
    this.edit.json = this.json
  }

  private async save() {
    const config = productsConfig.get(this.company_id)
    await config.updateProduct(this.json)
  }
}
