import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Bottom } from 'saas/page/product/bot/feed/Bottom'
import { Feeds } from 'saas/page/product/bot/feed/Feeds'
import { Search } from 'saas/page/product/bot/new/Search'
import { bot } from 'saas/store/product/ProductSaasController'

export const FeedCard = observer(() => {
  const onCreate = useLatestCallback(() => {
    bot.it.openForm()
  })

  return <Card vertical pad={16} wide>
    <Space gap={[12, 16]} opposite wrap>
      <Space gap={8}>
        <Icon size={24} name="outgoing_mail" secondary />
        <Typo size={15} semi>{i18n('bot.Mailings')}</Typo>
      </Space>
      <Space gap={8} wrap>
        <Space width={360}>
          <Search />
        </Space>
        <Button primary icon={<Icon size={16} name="add_circle" />} action={onCreate}>
          {i18n('common.Create')}
        </Button>
      </Space>
    </Space>
    <Gap height={16} />
    <Feeds />
    <Gap height={16} />
    <Bottom />
  </Card>
})
