import { Radio as RadioAntd } from 'antd'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import css from './Radio.module.scss'

type Props<Data> = {
  name?: string
  allowUncheck?: boolean
  checked: boolean
  children: ReactNode
  data?: Data
  error?: boolean
  onChange(checked: boolean, data: Data): void
  onFocus?(focus: boolean): void
  vertical?: boolean
  className?: string
}

export const Radio = <Data = void,>(props: Props<Data>) => {
  const {
    name,
    allowUncheck,
    checked,
    children,
    data,
    error,
    vertical,
    className,
  } = props
  const classes = clsx(
    css.radio,
    error && css.error,
    vertical && css.vertical,
    className && className
  )

  const onClick = () => {
    if (!checked || allowUncheck) props.onChange(!checked, data!)
  }

  return (
    <RadioAntd
      name={name}
      className={classes}
      checked={checked}
      onClick={onClick}
    >
      {children}
    </RadioAntd>
  )
}
