import { Button } from 'component/Button'
import { Input } from 'component/Input'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { WatermarkUpload } from './WatermarkUpload'
import { Field } from 'component/Field'
import { watermarkModal } from 'saas/store/watermark/WatermarkModalStore'
import { observer } from 'mobx-react-lite'
import { Option, Select } from 'component/Select/Select'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { useEffect, useMemo, useState } from 'react'
import { Spinner } from 'component/Spinner'

export const WatermarkEditModal = observer(() => {
  const { keys, watermarks } = companyEdit.it
  const { name, image, accessKey, isEditing, busy } = watermarkModal.it

  const [alreadyBusy, setAlreadyBusy] = useState(
    watermarks.map(({ access_key }) => access_key)
  )

  useEffect(() => {
    setAlreadyBusy(watermarks.map(({ access_key }) => access_key))
  }, [watermarks])

  const handleFileChange = (file: File) => {
    image.onChange(file)
  }

  const handleClose = () => {
    watermarkModal.close()
  }

  const handleSubmit = () => {
    watermarkModal.it.applyWatermark()
  }

  const handleNameChange = (file: string) => {
    name.onChange(file)
  }

  const onAccessKeyChange = (value: string) => {
    let next: string[] = []
    if (watermarkModal.it.isEditing) {
      next = alreadyBusy.filter((item) => item !== accessKey.value)
      setAlreadyBusy([...next, value])
    }
    accessKey.onChange(value)
  }

  const title = isEditing
    ? i18n('company.Watermarks.EditModal.TitleEdit')
    : i18n('company.Watermarks.EditModal.TitleAdd')

  const disabled =
    useMemo(() => {
      let sorted = alreadyBusy

      if (watermarkModal.it.isEditing) {
        sorted = alreadyBusy.filter(
          (access_key) => access_key !== accessKey.value
        )
      }

      return (
        !image.value ||
        !name.value ||
        (accessKey.value && sorted.includes(accessKey.value))
      )
    }, [accessKey.value, alreadyBusy, image.value, name.value]) || busy

  const options: Option[] = useMemo(
    () => [
      {
        label: i18n('company.Watermarks.EditModal.WithoutKey'),
        value: '',
      },
      ...keys.map((key) => {
        return {
          label: key.name,
          value: key.value,
          disabled: alreadyBusy.includes(key.value!),
        }
      }),
    ],
    [alreadyBusy, keys]
  )

  return (
    <Modal title={title} onCancel={handleClose}>
      <Vertical gap={12}>
        <Field label={i18n('company.Watermarks.EditModal.Label.Name')}>
          <Input value={name.value} onChange={handleNameChange} />
        </Field>
        <Field label={i18n('company.Watermarks.EditModal.Label.ApiKey')}>
          <Select
            wide
            value={accessKey.value}
            options={options}
            onChange={onAccessKeyChange}
            allowClear={false}
          />
        </Field>
        <WatermarkUpload value={image.value} onChange={handleFileChange} />
        <Typo size={13}>{i18n('company.Watermarks.EditModal.Text')}</Typo>
      </Vertical>
      <Space spread gap={12}>
        <Button action={handleClose}>{i18n('common.Cancel')}</Button>
        <Button disabled={disabled} primary action={handleSubmit}>
          {i18n('common.Save')}
          {busy && (
            <>
              {' '}
              <Spinner />
            </>
          )}
        </Button>
      </Space>
    </Modal>
  )
})
