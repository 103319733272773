import { notice } from 'app/notice'
import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Sticker } from 'component/Glass'
import { Link } from 'component/Link'
import { Scroll } from 'component/Scroll'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import hljs from 'highlight.js'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { tryon } from 'saas/store/product/ProductSaasController'
import { copyText } from 'util/browser'

export const EmbedCode = observer(() => {
  const { code } = tryon.it
  const high = useMemo(() => {
    return hljs.highlight(code, { language: 'html' }).value
  }, [code])

  const onCopy = useLatestCallback(async () => {
    await copyText(code)
    notice.success(i18n('message.Copied'))
  })

  return <Vertical gap={8} oh>
    <Space gap={[8, 12]} opposite wrap>
      <Typo size={15} semi>
        {i18n('product.EmbedCode')}
      </Typo>
      <Link href="/docs/widget" secondary>
        <Space gap={4}>
          <Icon size={16} name="info" />
          {i18n('label.Documentation')}
        </Space>
      </Link>
    </Space>
    <Card panel height={188} top oh>
      <Sticker pin={[8, 8, null, null]}>
        <IconButton action={onCopy}>
          <Icon size={16} name="content_copy" secondary />
        </IconButton>
      </Sticker>
      <Scroll>
        <Space>
          <Space pad={[8, 12]}>
            <Typo size={12} mono pre>
              <div dangerouslySetInnerHTML={{ __html: high }} />
            </Typo>
          </Space>
        </Space>
      </Scroll>
    </Card>
  </Vertical>
})
