import { Button } from 'component/Button'
import { Td, Tr } from 'component/Table'
import { Tag } from 'component/Tag'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { BotFeedStore } from 'saas/store/product/bot/BotFeedStore'
import { cfg } from 'store/cfg'
import { CampaignStatus, getCampaignStatusIcon, getCampaignStatusLabel } from 'type/Feed'
import { formatDate, formatTime, parseDate } from 'util/date'
import { formatInt, parseIntFinite } from 'util/number'
import { space } from 'util/typo'

type Props = {
  feed: BotFeedStore
}

function renderStatus(status: CampaignStatus): ReactElement {
  const icon = getCampaignStatusIcon(status)
  const label = getCampaignStatusLabel(status)
  const error = status === 'error'
  const success = status === 'completed'
  const warning = status === 'sending'
  return <Tag icon={icon} error={error} success={success} warning={warning}>
    {label}
  </Tag>
}

export const FeedRow = observer(({ feed }: Props) => {
  const { bot_feed } = cfg.cfg
  const {
    campaign_name,
    sent_at,
    campaign_status,
    messages_sent,
  } = feed.json
  const canStart = !!bot_feed && campaign_status === 'created'

  const onStart = useLatestCallback(async () => {
    await feed.start()
  })

  const count = formatInt(parseIntFinite(messages_sent))
  return <Tr>
    <Td width={240}>
      <Typo size={13} medium wrap>
        {campaign_name}
      </Typo>
    </Td>
    <Td width={80}>
      {count && <Tag icon="person">
        {count}
      </Tag>}
    </Td>
    <Td width={120}>
      <Typo>{formatDate(parseDate(sent_at))}</Typo>
      {space}
      <Typo secondary>{formatTime(parseDate(sent_at))}</Typo>
    </Td>
    <Td width={120}>
      {renderStatus(campaign_status)}
    </Td>
    <Td width={120}>
      {canStart && <Button small primary action={onStart}>
        <Icon size={16} name="send" fill />
        {i18n('feed.Send')}
      </Button>}
    </Td>
  </Tr>
})
