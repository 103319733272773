import { InputNumber as InputNumberAntd, InputNumberProps } from 'antd'

import css from './Input.module.scss'
import clsx from 'clsx'

export const InputNumber = (props: InputNumberProps) => {
  return (
    <InputNumberAntd
      inputMode="numeric"
      {...props}
      className={clsx(css.input, props.className)}
    />
  )
}
