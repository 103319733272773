import { Button } from 'component/Button'
import {
  Placement,
  Popover,
  PopoverControl,
  usePopoverControl,
} from 'component/Popover'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { ClickAction } from 'hook/useOnClickAction'
import {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react'
import css from './Dropdown.module.scss'

type ActionItem = {
  key: string
  label: ReactNode
  icon?: ReactNode
  action?: ClickAction | false
  hidden?: boolean
}

type Separator = {
  divider: true
}

export type DropdownItem = ActionItem | Separator

type DropdownContext = PopoverControl & {
  onClick(key: string): void
}

type ActionItemProps = {
  item: ActionItem
  context: DropdownContext
}

const ActionItemCom = ({ item, context }: ActionItemProps) => {
  const { icon, label, hidden } = item

  const onClick = useLatestCallback(() => {
    context.setOpen(false)
    context.onClick(item.key)
  })

  if (hidden) return null

  return (
    <Button
      className={css.button}
      text
      left
      action={item.action}
      onClick={onClick}
    >
      {icon && <div className={css.icon}>{icon}</div>}
      {label}
    </Button>
  )
}

const DividerCo = () => {
  return <div className={css.divider} />
}

type MenuProps = {
  items: DropdownItem[]
  context: DropdownContext
}

const Menu = ({ items, context }: MenuProps) => {
  return (
    <Vertical gap={4}>
      {items.map((item, index) => {
        if ('divider' in item) return <DividerCo key={-index} />
        else
          return <ActionItemCom key={item.key} item={item} context={context} />
      })}
    </Vertical>
  )
}

type DropdownProps = {
  className?: string
  children?: ReactNode
  items: DropdownItem[]
  onClick?(key: string): void
  placement: Placement
  space?: 4 | 8
  stretch?: boolean
}

export const Dropdown = (props: DropdownProps) => {
  const { className, children, items, placement, space = 4, stretch } = props
  const control = usePopoverControl()

  const onClickItem = useLatestCallback((key: string) => props.onClick?.(key))
  const context: DropdownContext = useMemo(
    () => ({ ...control, onClick: onClickItem }),
    [control, onClickItem]
  )
  const content = useLatestCallback(() => (
    <Menu items={items} context={context} />
  ))

  const onClickChild = useLatestCallback((event: MouseEvent) => {
    event.stopPropagation()
    control.setOpen(!control.open)
  })

  const clones = Children.map(children, (child) => {
    if (!isValidElement(child)) return child
    return cloneElement(child as ReactElement, { onClick: onClickChild })
  })

  return (
    <Popover
      className={className}
      control={control}
      stretch={stretch}
      placement={placement}
      pad={8}
      space={space}
      content={content}
    >
      {clones}
    </Popover>
  )
}
