import { i18n } from 'i18n'
import { makeAutoObservable } from 'mobx'
import { UploadImageFile } from 'saas/store/product/basic/UploadImageFile'
import { TryOnProduct, TryOnSelfiesData } from 'type/product/TryOnProduct'
import { notEmptyString } from 'util/string'

function few(selfies: readonly UploadImageFile[]) {
  const count = selfies.filter(it => it.src || it.busy).length
  const few = count > 0 && count < 3
  return few ? i18n('tryon.UploadAtLeastThreeSelfies') : ''
}

export class SelfiesStore {
  private _allowSelfieTemplate = false
  private _customSelfies = false
  readonly male = [new UploadImageFile(), new UploadImageFile(),
    new UploadImageFile(), new UploadImageFile(), new UploadImageFile()] as const
  readonly female = [new UploadImageFile(), new UploadImageFile(),
    new UploadImageFile(), new UploadImageFile(), new UploadImageFile()] as const

  constructor() {
    makeAutoObservable(this)
  }

  get allowSelfieTemplate(): boolean {
    return this._allowSelfieTemplate
  }

  set allowSelfieTemplate(value: boolean) {
    this._allowSelfieTemplate = value
    if (!value) this._customSelfies = false
  }

  get customSelfies(): boolean {
    return this._customSelfies
  }

  set customSelfies(value: boolean) {
    this._customSelfies = value
  }

  get canSave(): boolean {
    const busy = this.male.some(it => it.busy) || this.female.some(it => it.busy)
    const error = this.errorMale || this.errorFemale
    return !busy && !error
  }

  get errorMale(): string {
    return few(this.male)
  }

  get errorFemale(): string {
    return few(this.female)
  }

  get updates(): Partial<TryOnSelfiesData> {
    const _male = this.male.map(it => it.src).filter(notEmptyString)
    const _female = this.female.map(it => it.src).filter(notEmptyString)

    const templates = this._allowSelfieTemplate || undefined
    const custom = this._customSelfies || undefined
    const male = _male.length ? _male : undefined
    const female = _female.length ? _female : undefined

    return { templates, custom, male, female }
  }

  apply(json: TryOnProduct) {
    const male = json.selfies?.male ?? []
    const female = json.selfies?.female ?? []

    this._allowSelfieTemplate = !!json.selfies?.templates
    this._customSelfies = !!json.selfies?.custom
    this.male.forEach((it, n) => it.origin = male[n] || undefined)
    this.female.forEach((it, n) => it.origin = female[n] || undefined)
  }
}
