import { Table } from 'component/Table'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { FeedRow } from 'saas/page/product/bot/feed/FeedRow'
import { bot } from 'saas/store/product/ProductSaasController'

export const FeedTable = observer(() => {
  const { feeds } = bot.it.feeds

  return <Table>
    <Table.THead>
      <Table.Td>{i18n('label.Name')}</Table.Td>
      <Table.Td>{i18n('feed.Delivered')}</Table.Td>
      <Table.Td>{i18n('feed.SentDate')}</Table.Td>
      <Table.Td>{i18n('feed.Status')}</Table.Td>
      <Table.Td>{i18n('feed.Control')}</Table.Td>
    </Table.THead>
    <Table.TBody>
      {feeds.map(feed => <FeedRow key={feed.json.campaign_id} feed={feed} />)}
    </Table.TBody>
  </Table>
})
