import { app_name } from 'app/config/constants'
import { parseSortField, SortField } from 'feature/pagination/SortField'
import { makeAutoObservable, runInAction } from 'mobx'
import { api } from 'store/api'
import { ui } from 'store/home/ui/HomeUiStore'
import { me } from 'store/me'
import { ProductStore } from 'store/product'
import { ProductItem } from 'store/product/ProductItem'
import { route } from 'store/route'
import { CollectionId, SearchRequest, SearchResponse, SortRecord } from 'type/Search'
import { skipAsync } from 'util/async'
import { parseFinite } from 'util/number'
import { isEmptyObject, omit } from 'util/object'
import { loadJson, saveJson } from 'util/storage'

function loadPin(): boolean {
  return loadJson('search.pin') ?? true
}

function savePin(pin: boolean) {
  return saveJson('search.pin', pin)
}

const PAGE = 1
const LIMIT = 10
const SORT: SortField = 'updated_at'

function readPage(params: URLSearchParams): number {
  const page = parseFinite(params.get('page'))
  if (page) return Math.max(PAGE, Math.min(9999, page))
  return PAGE
}

function writePage(page: number): string | undefined {
  return page === PAGE ? undefined : page.toFixed()
}

function fixLimit(limit: number) {
  return Math.max(1, Math.min(100, limit))
}

export type SearchResult = {
  count: number
  page: number
  limit: number
  pages: number
  company_id: string
  items: ProductItem[]
  filtered: boolean
}

class ManagerSearchStore {
  private _pin = loadPin()

  private _page = PAGE
  private _limit = LIMIT
  private _query = ''
  private _sort: SortField = SORT
  private _company_id = ''
  private _collection_id = ''
  private _gender = ''
  private _brand: string[] = []
  private _internal_status: string[] = []
  private _external_status: string[] = []
  private _item_id: string = ''
  private _external_item_id: string = ''
  private _article: string = ''

  private _collections: CollectionId[] = []
  private _brands: string[] = []
  private _result?: SearchResult

  private _loading = false
  private _dirty = false

  constructor() {
    makeAutoObservable(this)
  }

  parseCompanyId(): string | undefined {
    const params = new URLSearchParams(location.search)
    return params.get('co') ?? undefined
  }

  formatCompanyLink(companyId: string | undefined): string {
    return companyId ? `/search?co=${companyId}` : '/search'
  }

  init(companyId: string) {
    const params = new URLSearchParams(location.search)

    this._page = readPage(params)
    this._limit = fixLimit(parseFinite(params.get('limit')) ?? LIMIT)
    this._query = params.get('query') ?? ''
    this._sort = parseSortField(params.get('sort')) ?? SORT
    this._company_id = companyId
    this._collection_id = params.get('cn') ?? ''
    this._gender = params.get('gender') ?? ''
    this._brand = params.get('brand')?.split(',') ?? []
    this._internal_status = params.get('status')?.split(',') ?? []
    this._external_status = params.get('review')?.split(',') ?? []
    this._item_id = params.get('item') ?? ''
    this._external_item_id = params.get('external') ?? ''
    this._article = params.get('article') ?? ''

    this._result = undefined
    void this.reloadConfig()
    void this.search()
  }

  reset() {
    this._page = PAGE
    this._limit = LIMIT
    this._query = ''
    this._sort = SORT
    this._collection_id = ''
    this._gender = ''
    this._brand = []
    this._internal_status = []
    this._external_status = []
    this._item_id = ''
    this._external_item_id = ''
    this._article = ''
    void this.search()
  }

  async search() {
    this._page = PAGE
    this.updateRoute()
    await this.searchInner()
  }

  get showFilterPanel() {
    return this.pin && !ui.window.mobile
  }

  get showFilterButton() {
    const saas = app_name === 'SAAS'
    return !saas && !this.showFilterPanel
  }

  get pin(): boolean {
    return this._pin
  }

  set pin(value: boolean) {
    this._pin = value
    savePin(value)
  }

  get count(): number {
    return this.result?.count ?? 0
  }

  get page(): number {
    return this._page
  }

  set page(page: number) {
    this._page = Math.max(PAGE, Math.min(page, this._result?.pages ?? 1))
    this.updateRoute()
    void this.searchInner()
  }

  get pages(): number {
    return this.result?.pages ?? 0
  }

  get limit(): number {
    return this._limit
  }

  set limit(value: number) {
    this._limit = value
    void this.search()
  }

  get query(): string {
    return this._query
  }

  set query(value: string) {
    this._query = value
    void this.search()
  }

  get sort(): SortField {
    return this._sort
  }

  set sort(value: SortField) {
    this._sort = value
    void this.search()
  }

  get company_id(): string {
    return this._company_id
  }

  set company_id(value: string) {
    this._company_id = value
    this._collection_id = ''
    this._brand = []
    void this.reloadConfig()
  }

  get collection_id(): string {
    return this._collection_id
  }

  set collection_id(value: string) {
    this._collection_id = value
  }

  get gender(): string {
    return this._gender
  }

  set gender(value: string) {
    this._gender = value
  }

  get brand(): string[] {
    return this._brand
  }

  set brand(value: string[]) {
    this._brand = value
  }

  get internal_status(): string[] {
    return this._internal_status
  }

  set internal_status(value: string[]) {
    this._internal_status = value
  }

  get external_status(): string[] {
    return this._external_status
  }

  set external_status(value: string[]) {
    this._external_status = value
  }

  get item_id(): string {
    return this._item_id
  }

  set item_id(value: string) {
    this._item_id = value
  }

  get external_item_id(): string {
    return this._external_item_id
  }

  set external_item_id(value: string) {
    this._external_item_id = value
  }

  get article(): string {
    return this._article
  }

  set article(value: string) {
    this._article = value
  }

  get busy(): boolean {
    return this._loading
  }

  get collections(): CollectionId[] {
    return this._collections
  }

  get brands(): string[] {
    return this._brands
  }

  get result(): SearchResult | undefined {
    return this._result
  }

  get filterCount(): number {
    const request = this.buildRequest()
    return Object.keys(omit(request.filters)).length
  }

  private reloadConfig = skipAsync(async () => {
    runInAction(() => {
      this._collections = []
      this._brands = []
    })
    if (!this.company_id) return

    const response = await api.getSearchConfig(this.company_id)
    runInAction(() => {
      this._collections = response.collections ?? []
      this._brands = response.brands ?? []
    })
  })

  private updateRoute() {
    const query = this.buildQuery()
    route.replace(route.pathname + query)
  }

  private readonly searchInner = skipAsync(async () => {
    runInAction(() => this._loading = true)
    try {
      let request: Required<SearchRequest>
      let response: SearchResponse
      do {
        runInAction(() => this._dirty = false)
        request = this.buildRequest()
        response = await api.search(request)
      } while (this._dirty)
      const { page, limit, company_id } = request
      const count = response.count || 0
      const pages = Math.ceil(count / limit)
      const items = response.items.map(item => {
        const store = new ProductStore(item, company_id)
        const collection = response.collections?.find(collection => collection.id === item.collection_id)
        store.data.collectionName = collection?.name
        return new ProductItem(undefined, store)
      })
      const filtered = !!request.query || !isEmptyObject(omit(request.filters))
      runInAction(() => this._result = { count, page, limit, pages, company_id, items, filtered })
    } finally {
      runInAction(() => this._loading = false)
    }
  })

  private readonly buildRequest = (): Required<SearchRequest> => {
    return {
      user_id: me.user.user_id,
      company_id: this.company_id,
      page: this._page,
      limit: this._limit,
      query: this._query,
      sort_by: this.buildSort(),
      filters: {
        collection_id: this._collection_id || undefined,
        dress_gender: this._gender || undefined,
        brand_name: this._brand.length ? this._brand : undefined,
        internal_status: this._internal_status.length ? this._internal_status : undefined,
        external_status: this._external_status.length ? this._external_status : undefined,
        item_id: this._item_id || undefined,
        external_item_id: this._external_item_id || undefined,
        article: this._article || undefined,
      },
    }
  }

  private buildSort(): SortRecord[] {
    const field = this.sort
    const order = 'desc'
    return [{ [field]: order }]
  }

  private buildQuery(): string {
    const params = this.params().toString()
    return params ? '?' + params : ''
  }

  private params(): URLSearchParams {
    const co = this._company_id
    const page = writePage(this._page)
    const limit = this._limit === LIMIT ? undefined : this._limit?.toFixed()
    const query = this._query || undefined
    const sort = this._sort === SORT ? undefined : this._sort
    const cn = this._collection_id || undefined
    const gender = this._gender || undefined
    const brand = this._brand.join(',') || undefined
    const status = this._internal_status.join(',') || undefined
    const review = this._external_status.join(',') || undefined
    const item = this.item_id || undefined
    const external = this.external_item_id || undefined
    const article = this.article || undefined

    return new URLSearchParams(omit({
      co,
      page,
      limit,
      query,
      sort,
      cn,
      gender,
      brand,
      status,
      review,
      item,
      external,
      article,
    }))
  }
}

export const managerSearchStore = new ManagerSearchStore()
