import dayjs from 'dayjs'
import { nil } from 'util/null'
import { parseIntFinite } from 'util/number'

export function parseDate(value?: string | number | nil): Date | undefined {
  if (!value) return undefined
  try {
    if (typeof value === 'number') return new Date(value)

    const time = parseIntFinite(value)
    const timestamp = time != null && time.toFixed() === value
    if (timestamp) return new Date(time)

    return new Date(value)
  } catch (error) {
    console.error('parse date failed', value, error)
    return undefined
  }
}

export function formatDate(date: Date, format?: string): string
export function formatDate(date: Date | nil, format?: string): undefined
export function formatDate(date: Date | nil, format = 'YYYY-MM-DD'): string | undefined {
  return date ? dayjs(date).format(format) : undefined
}

export function formatDateTime(date: Date): string
export function formatDateTime(date: Date | nil): string | undefined
export function formatDateTime(date: Date | nil): string | undefined {
  return formatDate(date, 'YYYY-MM-DD HH:mm')
}

export function formatTime(date: Date): string
export function formatTime(date: Date | nil): string | undefined
export function formatTime(date: Date | nil): string | undefined {
  return formatDate(date, 'HH:mm')
}

export function formatDateSec(date: Date): string
export function formatDateSec(date: Date | nil): string | undefined
export function formatDateSec(date: Date | nil): string | undefined {
  return formatDate(date, 'YYYY-MM-DD HH:mm:ss')
}
