import { makeAutoObservable } from "mobx"
import { api } from "store/api"
import { WatermarkImage, WatermarkItem, WatermarkItemData } from "type/Watermark"

export class WatermarkItemStore {
  id!: number
  watermark_id!: string
  watermark_name!: string
  company_id!: string
  access_key!: string
  watermark_data!: WatermarkItemData
  watermark_size!: string
  created_at!: string
  updated_at!: string
  is_default!: boolean
  is_deleted!: boolean
  watermark_image!: WatermarkImage[]
  edited?: boolean = true
  constructor() {
    makeAutoObservable(this)
  }

  static fromJson(json: WatermarkItem): WatermarkItemStore {
    const store = new WatermarkItemStore()
    store.applyJson(json)
    return store
  }

  async setDefault() {
    this.is_default = true
    await api.setDefaultWatermark(this.watermark_id, this.company_id)
  }

  applyJson(json: WatermarkItem) {
    this.id = json.id
    this.watermark_id = json.watermark_id
    this.watermark_name = json.watermark_name
    this.company_id = json.company_id
    this.access_key = json.access_key!
    this.watermark_data = json.watermark_data
    this.watermark_size = json.watermark_size
    this.created_at = json.created_at
    this.updated_at = json.updated_at
    this.is_default = json.is_default
    this.is_deleted = json.is_deleted
    this.watermark_image = json.watermark_image
  }
}