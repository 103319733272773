import { Card } from 'component/Card'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ApiKeySelect } from 'saas/page/statistics/ApiKeySelect'
import { IntervalSelect } from 'saas/page/statistics/IntervalSelect'
import { FormLabel } from './FormLabel'

export const Parameters = observer(() => {
  return <Card gap={16} pad={16} vertical wide>
    <Vertical gap={16}>
      <FormLabel title={i18n('statistics.ChooseApiKey')}>
        <ApiKeySelect />
      </FormLabel>

      <FormLabel title={i18n('statistics.ChoosePeriod')}>
        <IntervalSelect />
      </FormLabel>
    </Vertical>
  </Card>
})
