import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Switch } from 'component/Switch/Switch'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { StatusTag } from 'saas/page/product/common/StatusTag/StatusTag'
import { stand } from 'saas/store/product/ProductSaasController'

export const Status = observer(() => {
  const { busy, json } = stand.it
  const { published } = json

  const onPublish = useLatestCallback((publish: boolean) => {
    void stand.it.publish(publish)
  })

  return <Vertical maxWidth={480}>
    <Typo size={15} semi>
      {i18n('product.PublishStatus')}
    </Typo>
    <Gap height={12} />
    <Card pad={16} tertiary vertical top medium>
      <Space gap={12} wrap>
        <Typo size={13} semi>
          {i18n('product.PublishProduct')}
        </Typo>
        <Switch checked={!!published} onChange={onPublish} disabled={busy} />
      </Space>
      <Gap height={12} />
      <StatusTag />
    </Card>
  </Vertical>
})
