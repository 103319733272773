import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable, observable } from 'mobx'
import { SelfiesStore } from 'saas/store/product/tryon/SelfiesStore'
import { StringStore } from 'store/base/StringStore'
import { TryOnProduct } from 'type/product/TryOnProduct'
import { fixText } from 'util/form'
import { equal, updateObject } from 'util/object'

export type Data = 'email' | 'phone' | ''

export class TryOnEditStore {
  private _json: TryOnProduct

  private form = new FormStore()
  readonly name = this.form.field(new StringStore(), { required: true, fix: fixText })

  private _lang: string | undefined
  private _theme: string | undefined
  // color
  private _primary: string | undefined
  private _text: string | undefined
  private _background: string | undefined
  // user data
  private _data: Data = ''
  private _title: string | undefined
  private _description: string | undefined
  private _agreement: string | undefined
  private _delay: number | undefined
  private _timeout: number | undefined
  private _thanks: string | undefined
  // selfie
  readonly selfies = new SelfiesStore()

  constructor(json: TryOnProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this.applyJson(json)
  }

  get json(): TryOnProduct {
    return this._json
  }

  set json(value: TryOnProduct) {
    this._json = value
  }

  get lang(): string | undefined {
    return this._lang
  }

  set lang(value: string | undefined) {
    this._lang = value
  }

  get theme(): string | undefined {
    return this._theme
  }

  set theme(value: string | undefined) {
    this._theme = value
  }

  get primary(): string | undefined {
    return this._primary
  }

  set primary(value: string | undefined) {
    this._primary = value
  }

  get text(): string | undefined {
    return this._text
  }

  set text(value: string | undefined) {
    this._text = value
  }

  get background(): string | undefined {
    return this._background
  }

  set background(value: string | undefined) {
    this._background = value
  }

  get data(): Data {
    return this._data
  }

  set data(value: Data) {
    this._data = value
  }

  get title(): string | undefined {
    return this._title
  }

  set title(value: string | undefined) {
    this._title = value
  }

  get description(): string | undefined {
    return this._description
  }

  set description(value: string | undefined) {
    this._description = value
  }

  get agreement(): string | undefined {
    return this._agreement
  }

  set agreement(value: string | undefined) {
    this._agreement = value
  }

  get delay(): number | undefined {
    return this._delay
  }

  set delay(value: number | undefined) {
    this._delay = value == null ? undefined : Math.abs(value)
  }

  get timeout(): number | undefined {
    return this._timeout
  }

  set timeout(value: number | undefined) {
    this._timeout = value == null ? undefined : Math.abs(value)
  }

  get thanks(): string | undefined {
    return this._thanks
  }

  set thanks(value: string | undefined) {
    this._thanks = value
  }

  get updates(): Partial<TryOnProduct> {
    const {
      lang, theme, primary, text, background,
      data, title, description, agreement, delay, timeout, thanks,
    } = this

    const name = this.name.value || undefined
    const color = { primary, text, background }
    const on = !!data
    const type = data || undefined

    const email = { on, type, title, description, agreement, delay, timeout, thanks }
    const selfies = this.selfies.updates
    return { name, lang, theme, color, email, selfies }
  }

  get changed(): boolean {
    const updated = updateObject(this.json, this.updates)
    return !equal(this.json, updated)
  }

  get canSave(): boolean {
    return !this.form.error && this.selfies.canSave && this.changed
  }

  check() {
    return this.form.check() && this.canSave
  }

  private applyJson(json: TryOnProduct) {
    const on = json.email?.on
    const type = json.email?.type

    this.name.value = json.name || ''
    this._lang = json.lang
    this._theme = json.theme
    this._primary = json.color?.primary
    this._text = json.color?.text
    this._background = json.color?.background
    this._data = on ? type === 'phone' ? 'phone' : 'email' : ''
    this._title = json.email?.title
    this._description = json.email?.description
    this._agreement = json.email?.agreement
    this._delay = json.email?.delay
    this._timeout = json.email?.timeout
    this._thanks = json.email?.thanks
    this.selfies.apply(json)
  }
}
