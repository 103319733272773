import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { makeAutoObservable, observable } from 'mobx'
import { BotEditStore } from 'saas/store/product/bot/BotEditStore'
import { BotFeedsStore } from 'saas/store/product/bot/BotFeedsStore'
import { FeedFormStore } from 'saas/store/product/bot/FeedFormStore'
import { productsConfig, ProductsConfigStore } from 'saas/store/products/ProductsConfigStore'
import { api } from 'store/api'
import { BotProduct } from 'type/product/BotProduct'
import { onceAsync, skipAsync } from 'util/async'
import { updateObject } from 'util/object'

export class BotProductStore {
  readonly company_id: string
  readonly feeds = new BotFeedsStore()
  private _json: BotProduct
  private _edit!: BotEditStore
  private _form: FeedFormStore | undefined

  constructor(company_id: string, json: BotProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this.company_id = company_id
    this._json = json
  }

  get product_id(): string {
    return this._json.product_id
  }

  get json(): BotProduct {
    return this._json
  }

  get busy(): boolean {
    return this.config.busy
  }

  get edit(): BotEditStore {
    return this._edit
  }

  get form(): FeedFormStore | undefined {
    return this._form
  }

  openForm() {
    this._form = new FeedFormStore(this._json)
  }

  closeForm() {
    this._form = undefined
  }

  open() {
    this._edit = new BotEditStore(this._json)
  }

  readonly load = onceAsync(async () => {
    const { company_id, product_id } = this.json
    const feeds = await api.getBotFeeds(company_id, product_id)
    this.feeds.init(feeds)
  })

  readonly saveSettings = skipAsync(async () => {
    await this.edit.connect()
    if (!this.edit.check()) return

    const { updates } = this.edit
    const json = updateObject(this._json, updates)
    this.update(json)

    await this.save()
    notice.success(i18n('message.Saved'))
  })

  async createFeed() {
    if (!this.form) throw new Error('no form')
    const feed = await this.form.create()
    this.feeds.add(feed)
    notice.success('Created')
    this.closeForm()
  }

  private get config(): ProductsConfigStore {
    return productsConfig.get(this.company_id)
  }

  private update(json: BotProduct) {
    json.updated_at = new Date().toISOString()
    this._json = json
    this._edit.update(json)
    this._form?.update(json)
  }

  private async save() {
    await this.config.updateProduct(this._json)
  }
}
