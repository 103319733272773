// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomSelfie-image-1caf{overflow:hidden;width:72px;height:72px}.CustomSelfie-image-1caf img{width:100%;height:100%;object-fit:cover}.CustomSelfie-pale-1caf{opacity:.2}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/tryon/SelfiesPanel/CustomSelfie.module.scss"],"names":[],"mappings":"AAAA,yBACE,eAAA,CACA,UAAA,CACA,WAAA,CAEA,6BACE,UAAA,CACA,WAAA,CACA,gBAAA,CAIJ,wBACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `CustomSelfie-image-1caf`,
	"pale": `CustomSelfie-pale-1caf`
};
module.exports = ___CSS_LOADER_EXPORT___;
