// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Radio-radio-d659 .ant-radio{margin-top:1px;align-self:start}.Radio-vertical-d659.ant-radio-wrapper{align-items:center}.Radio-vertical-d659 .ant-radio{align-self:center}.Radio-error-d659{color:var(--ui-text-color-error)}`, "",{"version":3,"sources":["webpack://./src/component/Radio/Radio.module.scss"],"names":[],"mappings":"AACE,6BACE,cAAA,CACA,gBAAA,CAKF,uCACE,kBAAA,CAEF,gCACE,iBAAA,CAIJ,kBACE,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `Radio-radio-d659`,
	"vertical": `Radio-vertical-d659`,
	"error": `Radio-error-d659`
};
module.exports = ___CSS_LOADER_EXPORT___;
