import { clsx } from 'clsx'
import { Space } from 'component/Space'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { BOT } from 'type/product/ProductType'
import css from './StatusTag.module.scss'

type Props = {
  product?: ProductSaasStore
}

function icon(product: ProductSaasStore) {
  const { type, published } = product.json
  const bot = type === BOT

  if (published) return <Icon size={12} name="check_circle" fill />
  else if (bot) return <Icon size={12} name="help" fill />
  else return <Icon size={12} name="stop_circle" />
}

function text(product: ProductSaasStore) {
  const { type, published } = product.json
  const bot = type === BOT

  if (published) return bot ? i18n('bot.Connected') : i18n('product.Published')
  else return bot ? i18n('bot.NotConnected') : i18n('product.NotPublished')
}

export const StatusTag = observer(({ product }: Props) => {
  product ??= productSaas.it

  const { published } = product.json

  return <Space className={clsx(css.status, published && css.success)}>
    {icon(product)}
    {text(product)}
  </Space>
})
