import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { CustomSelfies } from 'saas/page/product/tryon/SelfiesPanel/CustomSelfies'
import { CustomSwitch } from 'saas/page/product/tryon/SelfiesPanel/CustomSwitch'
import { TemplateSwitch } from 'saas/page/product/tryon/SelfiesPanel/TemplateSwitch'
import { tryon } from 'saas/store/product/ProductSaasController'

export const SelfiesPanel = observer(() => {
  const { allowSelfieTemplate, customSelfies } = tryon.it.edit.selfies

  return <Vertical gap={12}>
    <Space gap={12} opposite wrap>
      <Typo size={13} medium>
        {i18n('tryon.AllowChoosingSelfieTemplates')}
      </Typo>
      <TemplateSwitch />
    </Space>
    {allowSelfieTemplate && <Space gap={12} opposite wrap>
      <Vertical gap={4}>
        <Typo size={13} medium>{i18n('tryon.CustomSelfieTemplates')}</Typo>
        <Typo size={11} secondary>{i18n('tryon.OtherwiseBuiltinTemplatesWillBeUsed')}</Typo>
      </Vertical>
      <CustomSwitch />
    </Space>}
    {customSelfies && <CustomSelfies />}
    {customSelfies && <CustomSelfies female />}
  </Vertical>
})
