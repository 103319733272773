import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable, observable } from 'mobx'
import { api } from 'store/api'
import { StringStore } from 'store/base/StringStore'
import { me } from 'store/me'
import { BotFeed, CreateBotFeedRequest } from 'type/Feed'
import { BotProduct } from 'type/product/BotProduct'
import { fixText } from 'util/form'

export class FeedFormStore {
  private _json: BotProduct
  private form = new FormStore()
  readonly name = this.form.field(new StringStore(), { required: true, fix: fixText })
  readonly message = this.form.field(new StringStore(), { required: true })

  constructor(json: BotProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
  }

  get json(): BotProduct {
    return this._json
  }

  get canSave(): boolean {
    return !this.form.error && !!this.json.bot_id
  }

  check() {
    return this.form.check() && this.canSave
  }

  update(json: BotProduct) {
    this._json = json
  }

  async create(): Promise<BotFeed> {
    const request = this.buildRequest()
    return await api.createBotFeed(request)
  }

  private buildRequest(): CreateBotFeedRequest {
    const bot_id = this.json.bot_id
    if (!bot_id) throw new Error('no bot_id')
    return {
      user_id: me.user.user_id,
      company_id: this.json.company_id,
      product_id: this.json.product_id,
      campaign_name: this.name.value,
      campaign_text: this.message.value,
      campaign_status: 'created',
    }
  }
}
