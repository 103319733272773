import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ImageUpload } from 'saas/page/company/common/ImageUpload'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'

export const DataSection = observer(() => {
  const { company_id, company, name, description, link, logo } = companyEdit.it
  const canEdit = can.EditCompanyInformation(company_id)

  return (
    <Vertical gap={16}>
      <Space gap={24} wide top>
        <Vertical gap={16} wide>
          <Space gap={8}>
            <Icon size={24} name="tune" tertiary />
            <Typo size={16} semi>
              {i18n('company.About')}
            </Typo>
          </Space>
          <Space gap={16} top wide>
            <Vertical gap={12} wide>
              <Space gap={12} wide>
                <TextField
                  field={name}
                  label={i18n('label.Name')}
                  disabled={!canEdit}
                />
                <TextField
                  field={link}
                  label={i18n('label.Website')}
                  disabled={!canEdit}
                />
              </Space>
              <TextField
                rows={4}
                field={description}
                label={i18n('label.Description')}
                disabled={!canEdit}
              />
            </Vertical>
          </Space>
        </Vertical>
        <Vertical gap={16}>
          <Typo size={16} semi>
            {i18n('company.CompanyLogo')}
          </Typo>
          <Space gap={16} top>
            <ImageUpload
              field={logo}
              disabled={!canEdit}
              original={company.json.logo_url}
              title={i18n('label.Logo')}
              description={i18n('label.LogoDescription')}
            />
          </Space>
        </Vertical>
      </Space>
    </Vertical>
  )
})
