import { notice } from 'app/notice'
import { FormStore } from 'form/store/FormStore'
import { i18n } from 'i18n'
import { makeAutoObservable } from 'mobx'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { ApiKeyModalStore, keyModal } from 'saas/store/key/ApiKeyModalStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { MemberStore } from 'saas/store/MemberStore'
import { WebhookFormStore } from 'saas/store/WebhookFormStore'
import { api } from 'store/api'
import { ImageFile } from 'store/base/ImageFile'
import { mx } from 'store/base/mx'
import { ObjectStore } from 'store/base/ObjectStore'
import { SetStore } from 'store/base/SetStore'
import { StringStore } from 'store/base/StringStore'
import { me } from 'store/me'
import { UpdateCompanyRequest } from 'type/Company'
import { applyWebhookUpdate } from 'type/CompanyConfig'
import { minAsync } from 'util/async'
import { matchSearch } from 'util/search'
import { watermarkModal, WatermarkModalStore } from './watermark/WatermarkModalStore'
import { WatermarkItemStore } from './watermark/WatermarkItemStore'
import { watermarkForm } from './watermark/WatermarkFormStore'
import { watermarkPositions } from 'saas/page/company/manage/sections/Watermark/components/WatermarkSelector/WatermarkPosition'

export class CompanyEditStore {
  readonly company: CompanySaasStore
  readonly company_id: string

  readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(''), { required: true })
  readonly description = this.form.field(new StringStore(''))
  readonly link = this.form.field(new StringStore(''))
  readonly logo = this.form.field(new ObjectStore<ImageFile>(), { required: true })

  private _keyDeleting?: ApiKeyStore

  private _memberDeleting?: MemberStore
  private _memberFilter = ''

  readonly webhook = mx.ref<WebhookFormStore>()

  private _companyDeleting = false

  constructor(company: CompanySaasStore) {
    makeAutoObservable(this)
    const { company_id, company_name, description, link, logo_url } = company.json
    this.company = company
    this.company_id = company_id
    this.name.value = company_name
    this.description.value = description ?? ''
    this.link.value = link ?? ''
    this.logo.value = ImageFile.fromUrlNil(logo_url)
  }

  get keys(): SetStore<ApiKeyStore> {
    return this.company.keys
  }

  get watermarks(): SetStore<WatermarkItemStore> {
    return this.company.watermarks
  }

  resetDefaultWatermark = () => {
    this.watermarks.map(watermark => watermark.applyJson({
      ...watermark,
      is_default: false
    })
    )
  }

  get canSave() {
    return !this.form.error && !this.saved
  }

  save = minAsync(async () => {
    if (!this.check()) return
    const request = this.buildCompanyRequest()
    const response = await api.updateCompany(request)
    this.company.update(response)

    if (watermarkForm.present && watermarkForm.it.canSave && watermarkForm.it.changed) {
      const { watermark_id, watermark_name, url, transparency, position, widthPercent, indentPercent } = watermarkForm.it
      const watermarkRequest = {
        company_id: this.company.company_id,
        watermark_id,
        watermark_name,
        url,
        watermark_data: {
          position: position.value as typeof watermarkPositions[number],
          transparency: transparency.value as number,
          widthPercent: widthPercent.value as number,
          indentPercent: indentPercent.value as number
        }
      }
      const data = await api.updateWatermark(watermarkRequest)
      const _watermark = this.watermarks.find(({ watermark_id: id }) => id === watermark_id)
      if (_watermark) _watermark.applyJson(data[0]!)
    }

    notice.success(i18n('message.Saved'))
  })

  openKeyEditModal(key?: ApiKeyStore) {
    keyModal.it = new ApiKeyModalStore(this.company, key)
  }

  openWatermarkModal(watermark?: WatermarkItemStore) {
    watermarkModal.it = new WatermarkModalStore(this.company, watermark)
  }

  openWebhookEdit() {
    this.webhook.it = new WebhookFormStore(this.company)
  }

  closeWebhookEdit() {
    this.webhook.close()
  }

  async saveWebhook() {
    const original = this.company.config.json
    const update = this.webhook.it.buildUpdate()
    const config = applyWebhookUpdate(original, update)
    this.webhook.close()
    await this.company.config.save(config)
  }

  async deleteKey() {
    const key = this.keyDeleting
    if (!key) throw new Error('no key')
    const value = key.value
    if (!value) throw new Error('no value')
    this.keys.remove(key)
    this.keyDeleting = undefined
    await api.deleteApiKey(value)
  }

  async deleteWatermark(watermark: WatermarkItemStore | undefined) {
    if (!watermark) throw new Error('no watermark')
    this.watermarks.remove(watermark)
    await api.deleteWatermark(watermark.watermark_id)
  }

  get keyDeleting(): ApiKeyStore | undefined {
    return this._keyDeleting
  }

  set keyDeleting(value: ApiKeyStore | undefined) {
    this._keyDeleting = value
  }

  get memberDeleting(): MemberStore | undefined {
    return this._memberDeleting
  }

  set memberDeleting(value: MemberStore | undefined) {
    this._memberDeleting = value
  }

  get memberFilter(): string {
    return this._memberFilter
  }

  set memberFilter(value: string) {
    this._memberFilter = value
  }

  get companyDeleting(): boolean {
    return this._companyDeleting
  }

  set companyDeleting(value: boolean) {
    this._companyDeleting = value
  }

  get filteredMembers(): MemberStore[] {
    return this.company.members.filter(m => matchSearch(this._memberFilter,
      [m.user_name, m.user_email, m.user_phone, m.user_descr]))
  }

  async deleteMember() {
    const member = this.memberDeleting
    if (!member) throw new Error('no member')
    this.company.members.remove(member)
    this.memberDeleting = undefined
    await api.deleteMember(member.user_id)
  }

  private get saved(): boolean {
    const { company_name, description, link, logo_url } = this.company.json

    return this.name.value === company_name
      && this.description.value === (description ?? '')
      && this.link.value === (link ?? '')
      && this.logo.value?.url === (logo_url || undefined)
      && (watermarkForm.present ? !watermarkForm.it.changed : true)
  }

  private check(): boolean {
    return this.form.check()
  }

  private buildCompanyRequest(): UpdateCompanyRequest {
    const { user_id } = me.user
    return {
      user_id,
      company_id: this.company_id,
      company_name: this.name.value,
      description: this.description.value,
      link: this.link.value,
      logo: this.logo.value?.file,
    }
  }
}

export const companyEdit = mx.ref<CompanyEditStore>()
