import { tryon_host } from 'app/config/constants'
import { CopyButton } from 'component/Button/CopyButton'
import { Link } from 'component/Link'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'
import { StandProductStore } from 'saas/store/product/stand/StandProductStore'
import { TryOnProductStore } from 'saas/store/product/tryon/TryOnProductStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { emdash, space } from 'util/typo'

function findStand(): undefined | StandProductStore {
  const id = bot.it.edit.window
  if (!id) return undefined
  const product = productsSaas.it.products.find(it => it.json.published && it.product_id === id)
  if (!product) return undefined
  if (product instanceof StandProductStore) return product
}

function findTryon(id?: string): TryOnProductStore | undefined {
  if (!id) return undefined
  const product = productsSaas.it.products.find(it => it.json.published && it.product_id === id)
  if (!product) return undefined
  if (product instanceof TryOnProductStore) return product
}

export const MiniAppDoc = observer(() => {
  const { company_id } = productsSaas.it.config
  const username = bot.it.json.bot_username
  const stand = findStand()
  const stand_id = stand?.json.product_id
  const stand_path = stand_id ? `/company/${company_id}/product/${stand_id}` : undefined
  const tryon = findTryon(stand?.json.widget)
  const mini = 'https://core.telegram.org/bots/webapps#direct-link-mini-apps'
  const father = 'https://t.me/botfather'
  const title = 'Try on'
  const link = tryon ? `https://${tryon_host}/app?product=${tryon.product_id}` : emdash
  const app = 'tryon'

  const onStand = useLatestCallback(() => {
    bot.it.edit.standRef?.focus()
  })

  if (!username) return null

  if (!stand) {
    return <Vertical>
      <Typo>
        To create links to try on clothes, please select
        {space}
        <Link primary action={onStand}>a Shop stand</Link>.
      </Typo>
    </Vertical>
  }

  if (!tryon) {
    return <Vertical>
      <Typo>
        To create links to try on clothes, please select a Try-on widget in
        {space}
        <Link primary path={stand_path}>the Shop stand</Link>.
      </Typo>
    </Vertical>
  }

  return <Vertical>
    <Typo>
      Use <Link primary href={father}>@BotFather</Link> to create
      {space}
      <Link primary href={mini}>Direct Link Mini App</Link>.
    </Typo>
    <Typo>
      Type command <Typo mono secondary>/newapp</Typo>.
      Choose the bot <Typo mono secondary>@{username}</Typo>.
    </Typo>
    <Typo>
      Enter a title <Typo secondary>{title}</Typo> <CopyButton small data={title} />.
    </Typo>
    <Typo>
      Enter any description.
      Upload any photo.
      Skip a demo GIF.
    </Typo>
    <Typo>
      Enter Web App URL <Typo secondary>{link}</Typo> <CopyButton small data={link} />.
    </Typo>
    <Typo>
      Enter a short name <Typo secondary>{app}</Typo> <CopyButton small data={app} />.
    </Typo>
  </Vertical>
})
