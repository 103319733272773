import { Tag } from 'component/Tag'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'

interface Props {
  tag: string
}

export const FilterTag = observer(({ tag }: Props) => {
  const value = tag.toLowerCase()

  const { selectedTags } = home.collections.it
  const selected = selectedTags.value.has(value)

  const onClick = useLatestCallback(() => selectedTags.toggle(value))

  return <Tag info={selected} action={onClick}>
    {tag}
  </Tag>
})
