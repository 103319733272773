import { Field } from 'component/Field'
import { Option, Select } from 'component/Select/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'
import { genders, getGenderLabel } from 'type/Gender'
import { nil } from 'util/null'

export const GenderSelect = observer(() => {
  const value = managerSearchStore.gender

  const options: Option[] = genders.map(gender => ({ value: gender, label: getGenderLabel(gender) }))

  const onChange = useLatestCallback((value: string | nil) => {
    managerSearchStore.gender = value || ''
  })

  return <Field label={i18n('label.Gender')}>
    <Select wide value={value} options={options} onChange={onChange} />
  </Field>
})
