import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ui } from 'store/home/ui/HomeUiStore'
import { formatInt } from 'util/number'

type Props = {
  count: number | undefined
}

export const CountText = observer(({ count }: Props) => {
  const { mobile } = ui.window
  const label = mobile ? i18n('search.FoundTotalShort') : i18n('search.FoundTotal')

  if (!count) return null

  return <Space gap={8}>
    <Typo size={13} secondary>{label}</Typo>
    <Typo size={13}>{formatInt(count)}</Typo>
  </Space>
})
