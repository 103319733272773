import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'
import { WatermarkItems } from './WatermarkItems'
import { watermarkDeleteModal } from 'saas/store/watermark/WatermarkDeleteModalStore'
import { WatermarkDeleteModal } from '../../modal/WatermarkModals/WatermarkDeleteModal'
import { observer } from 'mobx-react-lite'

export const Watermark = observer(() => {
  const { company_id } = companyEdit.it

  const onAdd = () => {
    companyEdit.it.openWatermarkModal()
  }

  return (
    <Card pad={24} gap={16} wide vertical>
      <Space wide opposite>
        <Space gap={8}>
          <Icon size={24} name="branding_watermark" tertiary />
          <Typo size={16} semi>
            {i18n('company.Watermarks.Title')}
          </Typo>
        </Space>
        {can.ManageApiKeys(company_id) && (
          <Button
            icon={<Icon size={16} name="add_circle" />}
            primary
            action={onAdd}
          >
            {i18n('company.Watermarks.ButtonAdd')}
          </Button>
        )}
      </Space>
      <WatermarkItems />
      {watermarkDeleteModal.present && <WatermarkDeleteModal />}
    </Card>
  )
})
