import { RadioGroup } from 'component/Radio/RadioGroup'
import { FormField } from 'form/FormField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { genders, getGenderLabel } from 'type/Gender'

export const GenderRadioGroup = observer(() => {
  const { gender } = modal.editCollectionModal.it

  const onChange = useLatestCallback((value: string) => {
    gender.value = value
  })

  const items = genders.map(gender => ({ value: gender, label: getGenderLabel(gender) }))

  return <FormField field={gender} label={i18n('label.Gender')}>
    <RadioGroup value={gender.value} onChange={onChange} items={items} />
  </FormField>
})
