import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Sticker } from 'component/Glass'
import { Space } from 'component/Space'
import { Upload } from 'component/Upload'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import css from 'saas/page/product/tryon/SelfiesPanel/CustomSelfie.module.scss'
import { UploadImageFile } from 'saas/store/product/basic/UploadImageFile'

type Props = {
  selfie: UploadImageFile
}

export const CustomSelfie = observer(({ selfie }: Props) => {
  const image = selfie
  const origin = image.origin
  const { value, busy, src } = image
  const changed = busy || value !== origin

  const onFile = useLatestCallback((file: File) => {
    image.file = file
  })

  const onDelete = useLatestCallback(() => {
    image.value = undefined
  })

  const onUndo = useLatestCallback(() => {
    image.value = origin
  })

  return <Space>
    <Upload active={!!src} accept="image" onFile={onFile}>
      <Space className={clsx(css.image, !value && css.pale)} center>
        {src ? <img src={src} /> : <Icon size={16} name="image" />}
      </Space>
    </Upload>
    {src && <Sticker pin={[2, 2, null, null]}>
      <IconButton small action={onDelete}>
        <Icon size={16} name="delete" />
      </IconButton>
    </Sticker>}
    {changed && <Sticker pin={[null, 2, 2, null]}>
      <IconButton small action={onUndo}>
        <Icon size={16} name="undo" />
      </IconButton>
    </Sticker>}
  </Space>
})
