// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag-tag-7a74{display:inline-flex;align-items:center;justify-content:center;height:24px;margin:0;padding:0 6px;font-size:11px;line-height:1;border-radius:4px;gap:6px}.Tag-error-7a74{color:var(--ui-text-color-error);background-color:var(--ui-background-color-error)}.Tag-warning-7a74{color:var(--ui-text-color-warning);background-color:var(--ui-background-color-warning)}.Tag-info-7a74{color:var(--ui-text-color-info);background-color:var(--ui-background-color-info)}.Tag-success-7a74{color:var(--ui-text-color-success);background-color:var(--ui-background-color-success)}.Tag-default-7a74{color:var(--ui-text-color-default);background-color:var(--ui-background-color-default)}.Tag-action-7a74:not(.Tag-busy-7a74){cursor:pointer}.Tag-action-7a74.Tag-busy-7a74{cursor:wait}.Tag-action-7a74:not(.Tag-busy-7a74):hover{border-color:var(--ui-primary-color)}`, "",{"version":3,"sources":["webpack://./src/component/Tag/Tag.module.scss"],"names":[],"mappings":"AAAA,cACE,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,QAAA,CACA,aAAA,CACA,cAAA,CACA,aAAA,CACA,iBAAA,CACA,OAAA,CAGF,gBACE,gCAAA,CACA,iDAAA,CAGF,kBACE,kCAAA,CACA,mDAAA,CAGF,eACE,+BAAA,CACA,gDAAA,CAGF,kBACE,kCAAA,CACA,mDAAA,CAGF,kBACE,kCAAA,CACA,mDAAA,CAGF,qCACE,cAAA,CAGF,+BACE,WAAA,CAGF,2CACE,oCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Tag-tag-7a74`,
	"error": `Tag-error-7a74`,
	"warning": `Tag-warning-7a74`,
	"info": `Tag-info-7a74`,
	"success": `Tag-success-7a74`,
	"default": `Tag-default-7a74`,
	"action": `Tag-action-7a74`,
	"busy": `Tag-busy-7a74`
};
module.exports = ___CSS_LOADER_EXPORT___;
