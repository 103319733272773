export type ApiKey = {
  key_name: string
  access_key: string
  created_at: string
  origins?: string | null
  read_only?: 0 | 1
  is_deleted?: 0 | 1
}
export type CreateApiKeyRequest = {
  user_id: string
  company_id: string
  name: string
  origins: string
}
export type UpdateApiKeyRequest = {
  user_id: string
  company_id: string
  access_key: string
  name: string
  origins: string
}
export type CreateApiKeyResponse = {
  access_key: string
}
export type UpdateApiKeyResponse = ApiKey | ApiKey[]

export function formatShortApiKey(value: string): string {
  const left = value.substring(0, 4)
  const right = value.substring(value.length - 4, value.length)
  return left + '…' + right
}
