import { ItemTag } from 'component/Tag'
import { useLatestCallback } from 'hook/useLatestCallback'
import { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { MouseEvent } from 'react'

export function renderSelectTag(props: CustomTagProps) {
  return <SelectTag {...props} />
}

type Props = CustomTagProps & {
  className?: string
}

export function SelectTag(props: Props) {
  const { className, label } = props

  const onClose = useLatestCallback((event: MouseEvent<HTMLElement>) => {
    props.onClose(event)
  })

  return <ItemTag className={className} data={undefined} onClose={onClose}>
    {label}
  </ItemTag>
}
