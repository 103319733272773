import clsx from 'clsx'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import css from './WatermarkSelector.module.scss'
import { watermarkForm } from 'saas/store/watermark/WatermarkFormStore'
import cssPositions from './WatermarkPosition.module.scss'

export const WatermarkSelectorPreview = observer(() => {
  const { position, transparency, widthPercent, indentPercent, url } =
    watermarkForm.it

  const styles: React.CSSProperties = {
    opacity: `${Number(transparency.value) * 10}%`,
    margin: `${indentPercent.value}%`,
  }

  if (widthPercent.value! > 0) {
    styles.width = `${widthPercent.value}%`
  }

  return (
    <Space className={clsx(css.preview)}>
      <div className={css.previewWrapper}>
        <div
          style={styles}
          className={clsx(
            css.watermarkPositionPreview,
            position !== null &&
              cssPositions[String(position.value).replace('-', '')]
          )}
        >
          <img src={url} alt="" />
        </div>
      </div>
    </Space>
  )
})
