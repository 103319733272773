import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Links } from 'saas/page/product/bot/details/Links'
import { Status } from 'saas/page/product/bot/details/Status'
import css from './Details.module.scss'

export const Details = observer(() => {
  return <Vertical gap={12} wide>
    <Space gap={8}>
      <Icon size={24} name="mode_off_on" tertiary />
      <Typo size={15} semi>
        {i18n('bot.Status')}
      </Typo>
    </Space>
    <Space className={css.root} gap={16} top wide wrap oh>
      <Status />
      <Links />
    </Space>
  </Vertical>
})
