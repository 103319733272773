// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemSelect-select-3efd input{padding-top:14px !important}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/tryon/Preview/ItemSelect.module.scss"],"names":[],"mappings":"AACE,8BACE,2BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `ItemSelect-select-3efd`
};
module.exports = ___CSS_LOADER_EXPORT___;
