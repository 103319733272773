import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Items } from 'saas/page/product/bot/items/Items'
import { Search } from 'saas/page/product/bot/items/Search'

export const ItemsCard = observer(() => {
  return <Card vertical gap={16} pad={16} wide>
    <Space gap={16} opposite>
      <Space gap={8}>
        <Icon size={24} name="apparel" tertiary />
        <Typo size={15} semi>{i18n('group.Items')}</Typo>
      </Space>
      <Search />
    </Space>
    <Items />
  </Card>
})
