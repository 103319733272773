// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WatermarkUpload-wrapper-8fd4{border:1px solid rgba(30,30,30,.08);padding:12px;border-radius:12px}.WatermarkUpload-upload-8fd4{background:rgba(82,123,244,.04);border-radius:12px;padding:8px}`, "",{"version":3,"sources":["webpack://./src/saas/page/company/manage/modal/WatermarkModals/WatermarkUpload.module.scss"],"names":[],"mappings":"AAAA,8BACE,mCAAA,CACA,YAAA,CACA,kBAAA,CAGF,6BACE,+BAAA,CACA,kBAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `WatermarkUpload-wrapper-8fd4`,
	"upload": `WatermarkUpload-upload-8fd4`
};
module.exports = ___CSS_LOADER_EXPORT___;
