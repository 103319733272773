import TelegramSvg from 'asset/icon-telegram.svg'
import { CopyButton } from 'component/Button/CopyButton'
import { Card } from 'component/Card'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'
import { emdash } from 'util/typo'

export const Links = observer(() => {
  const { bot_username } = bot.it.edit
  const href = `https://t.me/${bot_username}`
  return <Card pad={16} tertiary>
    <Space gap={12}>
      <TelegramSvg width={40} height={40} />
      <Vertical gap={4}>
        <Typo size={12} line={15} secondary>{i18n('bot.TelegramBotLink')}</Typo>
        {bot_username ? <Space gap={8} height={16}>
          <Link href={href}>
            {href.replace('https://', '')}
          </Link>
          <CopyButton data={href} />
        </Space> : <Typo size={12} tertiary>{emdash}</Typo>}
      </Vertical>
    </Space>
  </Card>
})
