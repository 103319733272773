import { app_name } from 'app/config/constants'
import { Button } from 'component/Button'
import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { IconName } from 'icon/types'
import { observer } from 'mobx-react-lite'
import { ArticleInput } from 'page/internal/search/ArticleInput'
import { BrandSelect } from 'page/internal/search/BrandSelect'
import { CollectionSelect } from 'page/internal/search/CollectionSelect'
import { CompanySelect } from 'page/internal/search/CompanySelect'
import { ExternalIdInput } from 'page/internal/search/ExternalIdInput'
import { GenderSelect } from 'page/internal/search/GenderSelect'
import { ItemIdInput } from 'page/internal/search/ItemIdInput'
import { ReviewSelect } from 'page/internal/search/ReviewSelect'
import { StatusSelect } from 'page/internal/search/StatusSelect'
import { ui } from 'store/home/ui/HomeUiStore'
import { managerSearchStore } from 'store/manager/search'

function content(buttons: JSX.Element) {
  if (ui.window.mobile) {
    const minWidth = 'min(200px, calc(100vw - 64px))'
    return <Space gap={12} wrap>
      <Space flex="1" minWidth={minWidth}><CompanySelect /></Space>
      <Space flex="1" minWidth={minWidth}><CollectionSelect /></Space>
      <Space flex="1" minWidth={minWidth}><GenderSelect /></Space>
      <Space flex="1" minWidth={minWidth}><BrandSelect /></Space>
      <Space flex="1" minWidth={minWidth}><StatusSelect /></Space>
      <Space flex="1" minWidth={minWidth}><ReviewSelect /></Space>
      <Space flex="1" minWidth={minWidth}><ItemIdInput /></Space>
      <Space flex="1" minWidth={minWidth}><ExternalIdInput /></Space>
      <Space flex="1" minWidth={minWidth}><ArticleInput /></Space>
      <Space flex="1" minWidth={minWidth}>{buttons}</Space>
    </Space>
  } else {
    return <Vertical gap={12}>
      <CompanySelect />
      <CollectionSelect />
      <GenderSelect />
      <BrandSelect />
      <StatusSelect />
      <ReviewSelect />
      <ItemIdInput />
      <ExternalIdInput />
      <ArticleInput />
      {buttons}
    </Vertical>
  }
}

export const Filters = observer(() => {
  const { pin } = managerSearchStore
  const { mobile } = ui.window
  const saas = app_name === 'SAAS'
  const expand: IconName = saas ? mobile ? 'left_panel_close' : 'right_panel_close' : 'keep'

  const onPin = useLatestCallback(() => {
    managerSearchStore.pin = !pin
  })

  const onReset = useLatestCallback(() => {
    managerSearchStore.reset()
  })

  const onApply = useLatestCallback(async () => {
    await managerSearchStore.search()
  })

  const buttons = <Space gap={8} spread wide wrap>
    <Button primary compact action={onApply}>
      {i18n('search.Apply')}
    </Button>
    <Button filled compact action={onReset}>
      {i18n('form.Reset')}
    </Button>
  </Space>

  return <Vertical gap={12} width={saas && mobile ? undefined : 240}>
    <Space gap={16} opposite>
      <Space gap={8}>
        <Icon size={16} name="filter_list" tertiary />
        <Typo size={16} bold>
          {i18n('search.Filters')}
        </Typo>
      </Space>
      <IconButton action={onPin}>
        <Icon size={16} name={expand} primary={!saas && pin} />
      </IconButton>
    </Space>
    {content(buttons)}
  </Vertical>
})
