import { Vertical } from 'component/Vertical'
import { WatermarkSelector } from './components/WatermarkSelector'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { companyEdit } from 'saas/store/CompanyEditStore'
import {
  watermarkForm,
  WatermarkFormStore,
} from 'saas/store/watermark/WatermarkFormStore'
import { WatermarkItemStore } from 'saas/store/watermark/WatermarkItemStore'

export const WatermarkItems = observer(() => {
  const { watermarks } = companyEdit.it

  const [active, setActive] = useState<number>()

  const handleChange = (watermark: WatermarkItemStore, index: number) => {
    const url = watermark.watermark_image
      ? watermark.watermark_image[0]?.url
      : ''

    watermarkForm.open(
      new WatermarkFormStore(
        watermark.watermark_id,
        watermark.watermark_name,
        url!,
        watermark.watermark_data
      )
    )
    setActive(index)
  }

  return (
    <Vertical gap={8}>
      {watermarks.map((watermark, index) => {
        return (
          <WatermarkSelector
            watermark={watermark}
            key={watermark.watermark_id}
            onChange={() => handleChange(watermark, index)}
            active={active === index}
          />
        )
      })}
    </Vertical>
  )
})
