import { SearchCustomSelect, SelectOption } from 'component/Select/SearchCustomSelect'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import type { BaseSelectRef } from 'rc-select/lib/BaseSelect'
import { useRef } from 'react'
import { statistics } from 'saas/store/stat/StatisticsStore'
import { ApiKey, formatShortApiKey } from 'type/ApiKey'

export const ApiKeySelect = observer(() => {
  const { key, keys } = statistics
  const disabled = !keys.length
  const ref = useRef<BaseSelectRef>()

  const onRef = useLatestCallback((value: BaseSelectRef | undefined) => {
    ref.current = value
  })

  const options: SelectOption<string, ApiKey>[] = keys.map((key, index) => ({
    key: index,
    value: key.key_name,
    data: key,
  }))

  const onChange = useLatestCallback((value?: string) => {
    statistics.key = value
  })

  const renderOption = useLatestCallback((value: string) => {
    const key = keys.find(key => key.key_name === value)
    if (!key) return value
    const code = formatShortApiKey(key.access_key)
    return <Space pad={[0, 8]} height={38} width={108}>
      <Vertical oh>
        <Typo size={13} line={1.2} medium ellipsis>{key.key_name}</Typo>
        <Typo size={10} line={1.2} medium secondary ellipsis>{code}</Typo>
      </Vertical>
    </Space>
  })

  return <SearchCustomSelect single value={key} options={options} disabled={disabled}
    onChange={onChange} onRef={onRef} renderOption={renderOption} height={50} />
})
