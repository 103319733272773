import { CopyButton } from 'component/Button/CopyButton'
import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Table } from 'component/Table'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'
import { GroupItem } from 'type/ItemGroup'
import { capitalize } from 'util/string'
import { emdash } from 'util/typo'

type ItemProps = {
  item: GroupItem
}

function buildLink(bot_username: string, external_item_id: string | undefined) {
  if (!(bot_username && external_item_id)) return undefined
  return `https://t.me/${bot_username}/tryon?startapp=${external_item_id}`
}

export const Item = observer(({ item }: ItemProps) => {
  const {
    product_name,
    brand_name,
    dress_gender,
    article,
    item_id,
    external_item_id,
    images,
  } = item
  const sources = images ?? []
  const source = sources[0]
  const { bot_username } = bot.it.edit
  const link = buildLink(bot_username, external_item_id)

  return (
    <Table.Tr>
      <Table.Td>
        <Space gap={12}>
          <Card round={8} height={70} width={40} style={{ minWidth: 34 }} pic shadow>
            {source && <Image src={source} sources={sources} />}
          </Card>
          <Vertical gap={4} width={300}>
            <Typo wrap size={13} ellipsis tertiary={!product_name}>
              {product_name || emdash}
            </Typo>
            <Space gap={8}>
              <Typo size={10} secondary>
                {i18n('label.Article')}
              </Typo>
              <Space gap={4} oh>
                <Typo size={10} secondary tertiary={!article} ellipsis>
                  {article || emdash}
                </Typo>
                {article && <CopyButton data={article} />}
              </Space>
            </Space>
            <Space gap={[0, 8]}>
              <Typo size={10} nowrap secondary>
                {i18n('label.ItemId')}
              </Typo>
              <Space gap={4} oh>
                <Typo size={10} secondary tertiary={!item_id} ellipsis>
                  {item_id || emdash}
                </Typo>
                {item_id && <CopyButton data={item_id} />}
              </Space>
            </Space>
            <Space gap={8}>
              <Typo size={10} nowrap secondary>
                {i18n('label.Sku')}
              </Typo>
              <Space gap={4} oh>
                <Typo size={10} secondary tertiary={!external_item_id} ellipsis>
                  {external_item_id || emdash}
                </Typo>
                {external_item_id && <CopyButton data={external_item_id} />}
              </Space>
            </Space>
          </Vertical>
        </Space>
      </Table.Td>
      <Table.Td>
        <Space width={80}>
          <Typo size={13} ellipsis tertiary={!dress_gender}>
            {capitalize(dress_gender) || emdash}
          </Typo>
        </Space>
      </Table.Td>
      <Table.Td>
        <Space width={160}>
          <Typo size={13} wrap tertiary={!brand_name}>
            {brand_name || emdash}
          </Typo>
        </Space>
      </Table.Td>
      <Table.Td>
        {link ? <Space gap={8} width={200}>
          <Link href={link}>
            {external_item_id}
          </Link>
          <CopyButton data={link} />
        </Space> : <Typo size={12} tertiary>{emdash}</Typo>}
      </Table.Td>
    </Table.Tr>
  )
})
