import { clsx } from 'clsx'
import { ClickAction, useOnClickAction } from 'hook/useOnClickAction'
import { Icon } from 'icon/Icon'
import { IconName } from 'icon/types'
import { Children, ReactNode } from 'react'
import css from './Tag.module.scss'

type Props = {
  action?: ClickAction | false
  children?: ReactNode
  className?: string
  error?: boolean
  icon?: IconName
  info?: boolean
  success?: boolean
  warning?: boolean
}

export function Tag(props: Props) {
  const { action, error, icon, info, success, warning } = props
  const { busy, onClick } = useOnClickAction(props)

  const color = error ? css.error : warning ? css.warning : info ? css.info : success ? css.success : css.default
  const className = clsx(props.className, css.tag, color, action != null && css.action, busy && css.busy)

  const children = Children.map(props.children, child => {
    return typeof child === 'string' ? <span>{child}</span> : child
  })

  return <div className={className} color={color} onClick={onClick}>
    {icon && <Icon size={12} name={icon} fill />}
    {children}
  </div>
}
