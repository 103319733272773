import { tryon_host } from 'app/config/constants'
import { makeAutoObservable } from 'mobx'
import { onceAsync, skipAsync } from 'util/async'
import { delay } from 'util/promise'

class TryOnPreviewStore {
  private _item_id = ''

  constructor() {
    makeAutoObservable(this)
  }

  get item_id(): string {
    return this._item_id
  }

  set item_id(value: string) {
    this._item_id = value
  }

  readonly preview = skipAsync(async (options: object, container: HTMLElement) => {
    await this.create()
    window.showoff!.setup!({ ...options, internal: { container } })
    const item = this._item_id || undefined
    window.showoff!.open!({ item })
  })

  private create = onceAsync(async () => {
    const host = window.tryon || tryon_host
    const element = document.createElement('script')
    element.src = `https://${host}/widget.js`
    element.async = true
    document.head.appendChild(element)
    while (!window.showoff?.setup) {
      await delay(100)
    }
  })
}

export const try_on_preview = new TryOnPreviewStore()
