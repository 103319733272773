import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import css from './WatermarkSelector.module.scss'
import clsx from 'clsx'
import { Typo } from 'component/Typo'
import { Icon } from 'icon/Icon'
import { WatermarkItemStore } from 'saas/store/watermark/WatermarkItemStore'
import { companyEdit } from 'saas/store/CompanyEditStore'
import {
  watermarkDeleteModal,
  WatermarkDeleteModalStore,
} from 'saas/store/watermark/WatermarkDeleteModalStore'
import {
  watermarkModal,
  WatermarkModalStore,
} from 'saas/store/watermark/WatermarkModalStore'
import { observer } from 'mobx-react-lite'
import { WatermarkSelectorForm } from './WatermarkSelectorForm'
import { WatermarkSelectorPreview } from './WatermarkSelectorPreview'
import { useCallback, useMemo } from 'react'
import { i18n } from 'i18n'
import { IconButton } from 'component/Button/IconButton'
import { Dropdown } from 'component/Popover/Dropdown'

type Props = {
  active: boolean
  onChange: () => void
  watermark: WatermarkItemStore
}

export const WatermarkSelector = observer(
  ({ watermark, active, onChange }: Props) => {
    const { company, resetDefaultWatermark } = companyEdit.it
    const { watermark_name, watermark_image, is_default, access_key } =
      watermark

    const classes = clsx(css.watermarkSection, active && css.active)

    const url = useMemo(
      () => (watermark_image ? watermark_image[0]?.url : ''),
      [watermark_image]
    )

    const onDelete = useCallback(() => {
      watermarkDeleteModal.open(new WatermarkDeleteModalStore(watermark))
    }, [watermark])

    const onEdit = useCallback(() => {
      const isEditing = true
      watermarkModal.open(
        new WatermarkModalStore(company, watermark, isEditing)
      )
    }, [company, watermark])

    const onDefault = useCallback(() => {
      resetDefaultWatermark()
      watermark.setDefault()
    }, [watermark, resetDefaultWatermark])

    const handleChange = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        if (!active) {
          onChange()
        }
      },
      [onChange, active]
    )

    const actions = useMemo(() => {
      return [
        {
          label: 'Редактировать',
          icon: <Icon size={16} name="edit" />,
          key: 'edit',
          action: onEdit,
        },
        {
          label: 'Назначить по умолчанию',
          key: 'default',
          icon: <Icon size={16} name="bookmark" />,
          action: onDefault,
          hidden: !access_key ? is_default : true,
        },
        {
          label: 'Удалить',
          key: 'delete',
          icon: <Icon size={16} name="delete" />,
          action: onDelete,
        },
      ]
    }, [access_key, is_default, onDefault, onDelete, onEdit])

    return (
      <Vertical pad={12} wide className={classes}>
        <Space gap={12} vertical wide>
          {/* <Radio
            className={css.radio}
            name="watermark_selector"
            checked={active}
            onChange={onChange}
          > */}
          <Space
            className={clsx(css.radio, active && css.active)}
            onClick={handleChange}
          >
            <Space gap={12} top wide>
              <div className={css.image}>
                <img src={url} alt="" />
              </div>
              <Vertical wide>
                <Vertical gap={8} className={css.title}>
                  <Typo size={13} semi>
                    {watermark_name}
                  </Typo>
                  {!active && (
                    <Typo size={11} className={css.subtitle}>
                      {i18n('company.Watermarks.form.Position')}:{' '}
                      {i18n(
                        // @ts-expect-error xxx
                        `company.Watermarks.Positions.${watermark.watermark_data.position.replace(
                          '-',
                          ''
                        )}`
                      )}
                      {watermark.watermark_data.widthPercent ? (
                        <>
                          {' '}
                          • {i18n('company.Watermarks.form.RightOffset')}:{' '}
                          {watermark.watermark_data.widthPercent}%
                        </>
                      ) : (
                        ''
                      )}
                      {watermark.watermark_data.indentPercent ? (
                        <>
                          {' '}
                          • {i18n('company.Watermarks.form.BottomOffset')}:{' '}
                          {watermark.watermark_data.indentPercent}%
                        </>
                      ) : (
                        ''
                      )}
                    </Typo>
                  )}
                </Vertical>
                {active && <WatermarkSelectorForm />}
              </Vertical>
              <Space gap={12}>
                {is_default ? (
                  <Space className={css.watermarkBageDefault}>
                    По умолчанию
                  </Space>
                ) : (
                  ''
                )}
                <Dropdown items={actions} placement="bottomRight">
                  <IconButton>
                    <Icon name="more_horiz" />
                  </IconButton>
                </Dropdown>
              </Space>
              {active && <WatermarkSelectorPreview />}
            </Space>
            {/* </Radio> */}
          </Space>
        </Space>
      </Vertical>
    )
  }
)
