import { Modal } from 'component/Modal'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { NewForm } from 'saas/page/product/bot/new/NewForm'
import { bot } from 'saas/store/product/ProductSaasController'

export const NewFeedModal = observer(() => {

  const onCancel = useLatestCallback(() => {
    bot.it.closeForm()
  })

  return <Modal title={i18n('bot.CreateMailing')} onCancel={onCancel}>
    <NewForm />
  </Modal>
})
