import { Card } from 'component/Card'
import { observer } from 'mobx-react-lite'
import { View } from 'saas/page/product/common/View/View'
import { Details } from 'saas/page/product/stand/Details/Details'
import { SettingsCard } from 'saas/page/product/stand/SettingsCard/SettingsCard'

export const StandView = observer(() => {
  return <View>
    <Card pad={16} wide>
      <SettingsCard />
    </Card>
    <Details />
  </View>
})
