import { Select } from 'component/Select/Select'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { can } from 'store/can'
import { ProductStore } from 'store/product'
import { buildExternalStatusOptions, ExternalStatus, fixReviewStatus } from 'type/ExternalStatus'

type Props = {
  className?: string
  product: ProductStore
}

export const ExternalStatusSelect = observer(({ className, product }: Props) => {
  const { companyId } = product
  const { status, review } = product.form
  const value = fixReviewStatus(review.value)
  const allowed = can.ManageProductStatuses(companyId) || status.value === 'done'
  const [busy, setBusy] = useState(false)

  const options = buildExternalStatusOptions(can.SystemAdmin(companyId))

  const onChange = useLatestCallback(async (value: ExternalStatus | '') => {
    setBusy(true)
    try {
      review.onChange(value || undefined)
      if (value === 'retry') status.onChange('edit_ex')
      if (value === 'cancel') status.onChange('disabled')
      await product.saveStatuses()
    } finally {
      setBusy(false)
    }
  })

  return <Select className={className} value={value} onChange={onChange}
    options={options} disabled={busy || !allowed} allowClear={false} hover
    title={i18n('placeholder.ReviewStatus')} placeholder={i18n('placeholder.ReviewStatus')} />
})
